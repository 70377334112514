/* Add to anchors containing buttons */
a.hasButton {
  text-decoration: none;
}

a.hasButton:visited {
  color: inherit;
}

/* "Reset" */
.root {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: var(--borderRadiusHalf);
  background: none;
  overflow: visible;
  font-size: 100%;
  font-family: inherit;
  text-transform: none;
  cursor: pointer;
  -webkit-appearance: button;
}

.root {
  display: flex;
  justify-content: center;
  align-items: center;
}

.root:hover {
  filter: brightness(85%);
}

.root:active,
.isActive {
  filter: brightness(70%);
}

.root[disabled],
.root[disabled]:focus {
  opacity: 0.4;
  filter: brightness(100%);
  cursor: not-allowed;
}

.root.isStatic:hover,
.root.isStatic:focus,
.root.isStatic:active {
  filter: brightness(100%);
  cursor: default;
}

/* Adapted from Bulma codebase */
/* As with Bulma, this doesn't work with `input type="submit"`. Prefer to use
 * `button type="submit"` instead. */
.root.isLoading::after {
  animation: spin 1.5s linear infinite;
  border: 2px solid black;
  border-radius: 50%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: "";
  display: block;
  height: 0.8em;
  position: absolute;
  width: 0.8em;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.isFullWidth {
  width: 100%;
}
