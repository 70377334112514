.root {
  position: absolute;
  z-index: 1;
  opacity: 0;
  animation: fadeIn 200ms ease-in 100ms forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.button {
  margin-top: 7px; /* Value depends on prompt editor spacing */
  margin-left: 60px;
  box-shadow: var(--boxShadow4);
  font-size: 85%;
}

.selectedOption {
  display: flex;
  align-items: center;
  width: 100%;
}

.optionLabel {
  flex: 1;
  text-align: center;
  margin-left: var(--spacingHalf);
}
