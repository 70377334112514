.root {
  padding: var(--spacing);
  box-shadow: var(--boxShadow4);
  border-radius: var(--borderRadius);
  border: 1px solid var(--surfaceDark);
  background-color: white;
  opacity: 0;
  animation: fadeIn 100ms ease-in 100ms forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
