.root {
  max-width: 250px;
  padding: var(--spacing3by2);
  border-radius: var(--borderRadius);
  background-color: var(--surfaceAccentLight);
}

.message {
  margin-bottom: var(--spacing3by2);
  color: var(--grayDark);
}
