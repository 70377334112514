.root {
  display: inline-block;
  width: 60px;
  height: 60px;
}

.root__isHighlighted {
  box-shadow: 0 0 0 2px var(--accent);
}

.img {
  width: 100%;
  height: 100%;
}
