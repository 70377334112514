.root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.bigPicture {
  flex-shrink: 0;
}

.analytics {
  flex: 1;
  width: 100%;
  padding: 0 calc((100% - 1080px)/2);
  overflow-y: auto;
  margin: 0 auto;
}

.promptBox {
  margin: var(--spacing);
  padding: var(--spacing);
  border-radius: var(--borderRadiusHalf);
  background-color: var(--surfaceAccent);
}

.promptBox__isExpanded {
  margin-left: calc(var(--spacing) * 4);
  background-color: var(--surfaceLight);
}

.box_key {
  margin-bottom: var(--spacingHalf);
  font-size: 80%;
  color: var(--gray);
}

.box_content {
  display: flex;
}

.box_message {
  flex: 1;
  overflow: hidden;
  /* Below props enable multiline ellipsis for truncated text.
   * It is supported in all modern browsers, yet not very encouraged.
   * See https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-line-clamp.
   */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  text-overflow: ellipsis;
}

.box_stat {
  flex-shrink: 0;
  margin-left: var(--spacing);
  color: var(--grayDark);
}

.answers {
  display: flex;
  flex-wrap: wrap;
}

.answerBox {
  max-width: 250px;
  max-height: 100px;
  margin: var(--spacing) var(--spacing) 0 0;
  padding: var(--spacing);
  border-radius: var(--borderRadiusHalf);
  background-color: var(--surface);
}
