.root {
  display: flex;
  justify-content: stretch;
}

.root > * {
  flex: 1;
}

.root > *:not(:first-child):not(:last-child) {
  border-radius: 0;
  border-right: 1px solid var(--grayLight);
}

.root > *:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 1px solid var(--grayLight);
}

.root > *:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
