.root {
  display: flex;
}

.input {
  flex: 1;
  /* Keep in sync with padding in MessageEditor, but leave left padding to be
   * determined by the Input itself, since it must account for a leading icon.
   */
  padding-top: 2px;
  padding-bottom: 2px;
  padding-right: 2px;
}

.input > * {
  color: var(--grayDark);
}
