.items {
  margin-bottom: var(--spacingDouble);
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: var(--spacing) 0;
  border-bottom: 1px solid var(--surface);
}

.item_info {
  flex: 1;
}

.item_info_title {
  display: flex;
  margin: var(--spacing) 0;
  font-size: 20px;
}

.item_info_title_button {
  margin-left: var(--spacingHalf);
}

.item_info_grid {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: var(--spacing);
}

.infoLabel {
  color: var(--grayDark);
  overflow-wrap: anywhere;
}

.infoValue {
  overflow-wrap: anywhere;
}

.actions {
  flex-shrink: 0;
}

.platformDetails > .infoLabel {
  margin-bottom: 2px;
}

.platformDetails > .infoValue {
  margin-bottom: var(--spacing3by4);
}
