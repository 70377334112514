.root {
  display: flex;
  align-items: center;
}

.input {
  width: 92px; /* Trial and error */
}

.units {
  margin-left: var(--spacing);
}
