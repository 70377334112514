.root {
  position: relative;
}

.static {
  display: flex;
}

.tipContent {
  margin: 0;
  padding: 0;
  list-style: none;
}

.static_later {
  padding: 0 var(--spacing);
  border-radius: var(--borderRadius) 0 0 var(--borderRadius);
  background-color: var(--surfaceDark);
  color: var(--grayDark);
}

.static_later__disabled {
  opacity: 0.2 !important;
}

.static_score {
  display: flex;
  align-items: center;
  min-width: 40px;
  padding: 0 var(--spacing);
  border-radius: 0 var(--borderRadius) var(--borderRadius) 0;
  background-color: var(--surface);
  color: var(--gray);
  font-size: 75%;
}

.static.__isLaterActive .static_later {
  background-color: hsl(40, 70%, 75%);
}

.static.__isLaterActive .static_score {
  background-color: hsl(40, 70%, 85%);
}

.editor {
  position: absolute;
  top: -7px;
  left: calc(-1 * var(--spacingHalf));
  z-index: 1;
  padding: var(--spacingHalf);
  box-shadow: var(--boxShadow4);
  background-color: white;
}

.editor > input {
  width: 70px;
  padding-left: 7px; /* Trial and error */
}
