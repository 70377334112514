.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.title {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: var(--grayDark);
}

.title a {
  color: var(--gray);
  white-space: nowrap;
}

/* To match `NameEditor` padding */
.title_name {
  margin-left: calc(var(--spacing) + 1px);
  margin-right: calc(var(--spacing) + 1px);
}

.stats {
  flex: 1;
  overflow-y: hidden;
}
