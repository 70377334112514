.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.logo {
  width: 22px;
  height: 22px;
  color: var(--accent);
}
