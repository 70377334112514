.label {
  margin-top: var(--spacing);
}

.editor {
  margin-top: var(--spacing);
  padding: var(--spacing);
  border-radius: var(--borderRadiusHalf);
  background-color: var(--surfaceLight);
}

.translationEditor {
  padding: var(--spacingHalf) var(--spacing);
}
