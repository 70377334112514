.path {
  stroke: var(--accent);
  stroke-width: 2px;
  fill: transparent;
}

.paths {
  position: absolute;
  width: 100%;
  height: 100%;
}
