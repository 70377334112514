.root {
  position: relative;
  padding: var(--spacing) 0;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}

.root__hovering {
  border-color: hsl(0, 0%, 85%);
}

.messageItem {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.dragHandle {
  flex-shrink: 0;
  margin-top: 4px; /* Trial and error */
  margin-right: var(--spacing); /* Trial and error */
}

.dragHandle path {
  stroke: var(--gray);
}

.editor {
  flex: 1;
}

.editor_step {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: var(--spacingHalf) 0;
  font-size: 80%;
  color: var(--gray);
}

.acceptAnswer {
  font-size: 90%;
  color: var(--primary);
}

.acceptAnswer_input {
  margin-top: 2px; /* Trial and error */
}

.delay {
  flex-basis: 100%;
  display: flex;
  align-items: center;
  margin-top: var(--spacing3by2);
}

.delay_text {
  font-size: 90%;
  color: var(--accent);
}

.delay_input {
  width: 70px; /* Trial and error */
  margin: 0 var(--spacingHalf);
}
.delay_input input {
  padding: 2px 4px;
  font-size: 90%;
}

.actionMenu {
  z-index: 30;
}

.actionMenuButton {
  color: var(--grayLight);
}
