.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.filter {
  flex-shrink: 0;
}

.results {
  flex: 1;
  overflow-y: auto;
}
