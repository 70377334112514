.root {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 140px; /* Trial and error */
  max-height: 150px;
  padding: var(--spacing) var(--spacingHalf);
  box-shadow: var(--boxShadow3);
  border-radius: var(--borderRadius);
  background-color: var(--surfaceLight);
  opacity: 0.6;
}

.root.__isOpen {
  opacity: 1;
}

.root:hover {
  opacity: 1;
}

.titleBadge {
  --size: 20px;
  --offset: calc(var(--size) / -2);

  position: absolute;
  top: var(--offset);
  left: var(--offset);
  width: var(--size);
  height: var(--size);
}

.title {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 85%;
  color: var(--grayDark);
}

.locations {
  flex: 1;
  overflow: auto;
  margin-top: var(--spacing);
}

.location {
  display: flex;
  align-items: center;
  padding: var(--spacing) var(--spacingHalf);
  font-size: 80%;
  cursor: pointer;
}

.location:hover {
  filter: brightness(90%);
  background-color: white;
}

.location_icon {
  display: flex;
  align-items: center;
}
