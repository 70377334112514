.root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.bigPicture {
  flex-shrink: 0;
}

.analytics {
  flex: 1;
  display: flex;
  width: 100%;
  overflow-y: hidden;
  margin: 0 auto;
  border-top: 1px solid var(--surfaceDark);
}

.itemsColumn {
  flex-shrink: 0;
  width: 25%;
  min-width: 300px;
  overflow-y: auto;
}

.item {
  display: flex;
  flex-direction: column;
  padding: var(--spacing);
  border-bottom: 1px solid var(--surfaceDark);
  background-color: var(--surfaceLight);
  overflow-x: hidden;
}

.item:hover {
  filter: brightness(0.97);
}

.item__selected {
  background-color: var(--surface);
}

.item__notStarted {
  background-color: white;
  cursor: not-allowed;
}

.item__notStarted:hover {
  filter: none;
}

.item_name {
  margin-bottom: var(--spacing);
  font-size: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.item__selected .item_name {
  color: var(--tertiaryDark);
}

.item__notStarted .item_name {
  color: var(--gray);
}

.item__notStarted .item_message {
  font-size: 90%;
  color: var(--gray);
}

.item_bigPicture {
  display: grid;
  grid-template-columns: 70px 1fr auto 70px 1fr;
  grid-row-gap: var(--spacingHalf);
  align-items: center;
}

.item_bigPicture .name {
  font-size: 80%;
  color: var(--grayDark);
}

.item_bigPicture .value {
  font-size: 85%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.item_bigPicture .divider {
  grid-row: 1 / 3;
  grid-column: 3;
  width: 1px;
  height: 100%;
  margin: 0 var(--spacing3by4);
  background-color: var(--grayLight);
}

.itemStatsColumn {
  flex: 1;
  min-width: 300px;
  background-color: var(--surface);
}
