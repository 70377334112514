.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: var(--spacing);
  border-bottom: 1px solid var(--surface);
}

.root:last-child {
  border-bottom: none;
}

.info {
  flex: 1;
}

.info_title {
  margin: var(--spacing) 0;
}

.info_owner {
  font-size: 85%;
  color: var(--primary);
}
.info_owner > span:first-child {
  color: var(--grayDark);
}

.actions {
  flex-shrink: 0;
}
