.root {
  height: 100%;
  overflow-y: auto;
}

.header {
  display: flex;
  align-items: center;
  padding: var(--spacing3by2);
}

.header_title {
  flex: 1;
  font-weight: bold;
  color: var(--grayDark);
}

.header_close {
  flex: none;
  color: var(--gray);
}

.ConfigItem {
  padding: var(--spacing) var(--spacing3by2);
}

.ConfigItem_title {
  padding-bottom: var(--spacing);
}

.ConfigGroup {
  padding: var(--spacing) 0;
}

.ConfigGroup_title {
  padding: 0 var(--spacing3by2) var(--spacing);
  border-bottom: 1px solid var(--grayLight);
  color: var(--gray);
  font-size: 90%;
  text-transform: uppercase;
}
