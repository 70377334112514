.root {
  display: flex;
  width: 100%;
  height: 100%;
  overflow-x: auto;
}

.itemsColumn {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  width: 25%;
  min-width: 200px;
  background-color: var(--surface);
}

.item {
  display: flex;
  align-items: center;
  padding: var(--spacingDouble) var(--spacing);
  border-bottom: 1px solid var(--surfaceDark);
  background-color: var(--surface);
  overflow-x: hidden;
}

.item:hover {
  filter: brightness(0.97);
}

.item__selected {
  background-color: white;
}

.item_info {
  flex: 1;
  overflow-x: hidden;
}

.item_info_title {
  font-size: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 26px; /* To prevent jank on IntegratedIcon appearance */
}

.item__selected .item_info_title {
  color: var(--tertiaryDark);
}

.integratedIcon {
  color: var(--success);
}

.integrationConfigColumn {
  flex: 1;
  min-width: 300px;
  overflow-y: auto;
  padding: var(--spacingDouble) var(--spacing);
}

.integrationConfigWrapper {
  max-width: 600px;
  margin: 0 auto;
}

.integrationConfig_title {
  margin-bottom: var(--spacing);
  color: var(--primary);
  font-size: 24px;
}

.integrated_message {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing);
  color: var(--success);
}

.integrated_editForm {
  margin-bottom: var(--spacing);
}

.integrated_actions:not(:empty) {
  display: flex;
  margin-top: var(--spacingDouble);
  padding-top: var(--spacingDouble);
  border-top: 1px solid var(--surfaceDark);
}

.integrated_actions > *:not(:last-child) {
  margin-right: var(--spacing);
}
