.message {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  width: 120px; /* Trial and error */
  margin-bottom: var(--spacing);
}
