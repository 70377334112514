.root {
}

.root.user {
  color: var(--accent);
}

.root.system {
  color: var(--gray);
}
