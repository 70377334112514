.root {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: bold;
}

.initial {
  text-transform: uppercase;
}

.imgAvatar {
  object-fit: cover;
  border-radius: 50%;
  box-shadow: var(--boxShadow3);
}
