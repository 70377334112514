.root {
  position: absolute;
  left: -10000px;
  z-index: 20;
  display: flex;
  /* Height needs to be fixed for `offsetHeight` value to be reported
   * correctly at all times */
  height: 32px;
  padding: var(--spacing);
  border-radius: var(--borderRadiusHalf);
  border: 1px solid white;
  background-color: black;
  opacity: 0;
  transition: opacity 0.75s;
}

.button {
  color: white;
  font-size: 14px;
  margin-right: 8px;
}

.button:last-child {
  margin-right: 0;
}
