.root {
  justify-content: flex-start; /* To negate FlatButton's `center` */
  padding: var(--spacing3by4) var(--spacing3by2);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--grayDark);
}

.root:hover {
  background-color: hsla(0, 0%, 50%, 0.1);
}

.root__selected {
  background-color: hsla(0, 0%, 50%, 0.2);
}
