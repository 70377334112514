/*** Box shaped nodes ***/

.node {
  position: absolute;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  padding: var(--spacingHalf);
  border-radius: var(--borderRadiusHalf);
  background-color: var(--surfaceAccent);
  font-size: 12px;
}

.node:hover {
  filter: brightness(85%);
}

.message {
  flex: 1;
  overflow: hidden;
  /* Below props enable multiline ellipsis for truncated text.
   * It is supported in all modern browsers, yet not very encouraged.
   * See https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-line-clamp.
   */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}

.tags {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
}

.tag_total,
.tag_dropped {
  display: inline-block;
  min-width: 30px;
  border-radius: var(--borderRadius);
  background-color: var(--surface);
  text-align: center;
}

.tag_total {
  background-color: var(--accent);
}

.tag_dropped {
  margin-top: 2px;
  background-color: var(--danger);
}

/*** Dot shaped nodes ***/

.dot {
  position: absolute;
  background-color: var(--accent);
  border-radius: 50%;
  display: flex;
  z-index: 10;
}

.dot:hover {
  filter: brightness(85%);
}

/*** Tippy related stuff ***/

.promptDetails_messages {
  min-width: 150px;
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: var(--spacing);
  padding: var(--spacingHalf);
  border-radius: 2px;
  background-color: var(--grayDark);
}

.promptDetails_message:not(:last-child) {
  margin-bottom: var(--spacingHalf);
  padding-bottom: var(--spacingHalf);
  border-bottom: 1px solid var(--gray);
}

.promptDetails_measurements {
  font-size: 90%;
}

.answerDetails {
  min-width: 150px;
  max-height: 300px;
  overflow-y: auto;
}

.answerDetails_title {
  margin-bottom: var(--spacingHalf);
}

.answerDetailsItem:not(:last-child) {
  margin-bottom: var(--spacing);
}

.answerDetailsItem_message {
  padding: var(--spacingHalf);
  border-radius: 2px 2px 0 0;
  background-color: var(--grayDark);
}

.answerDetailsItem_measurements {
  padding: var(--spacingHalf);
  border-radius: 0 0 2px 2px;
  background-color: var(--gray);
  font-size: 90%;
}