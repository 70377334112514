.root {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacingDouble);
}

.ownershipSelect {
  width: 110px;
  margin-right: var(--spacing);
}

.searchTermInput {
  width: 100%;
}
