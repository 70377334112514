.root {
  display: inline-flex;

  /* Set to match height in `PromptKeySelect`.
   * Extracted out here because it is needed in multiple classes below. */
  --itemHeight: 24px;
}

.brackets {
  margin: 0 2px;
  color: var(--gray);
  cursor: pointer;
}

.brackets:hover {
  filter: brightness(75%);
}

.brackets__selected {
  color: blue;
}

.inputWrap {
  display: inline-block;
  min-width: 65px;
}

/* Styles (especially dimensions) must match those in `PromptKeySelect` */
.input {
  width: 100%;
  height: var(--itemHeight);
  font-size: 85%;
}

.typeChangeButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--itemHeight);
  height: var(--itemHeight);
  margin-left: 2px;
}
