.label {
  margin-bottom: var(--spacing);
}

.field {
  display: flex;
  align-items: center;
}

.field:not(:last-child) {
  margin-bottom: var(--spacing);
}

.select {
  flex: 1;
}

.suffix {
  flex-shrink: 0;
  width: 80px; /* Trial and error */
  margin-left: var(--spacing3by2);
  font-size: 95%;
  color: var(--grayDark);
}
