.root {
  position: relative;
}

.unsupportedOverlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--overlayDanger);
}
