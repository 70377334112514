.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.body {
  flex: 1;
  overflow: hidden;
}
