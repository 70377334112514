.root {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  max-width: 100%;
  border-radius: var(--borderRadiusHalf);

  --leading-width: 22px;
  --input-paddingH: var(--spacing);
  --input-paddingV: var(--spacingHalf);
  --input-borderWidth: 1px;
  --input-borderColor: transparent;
}

.root__minimal {
  --leading-width: 22px;
  --input-paddingH: 0px; /* Not adding 'px' causes error when used in `calc` */
  --input-paddingV: 0px;
  --input-borderWidth: 0px;
}

.root__outlined {
  --input-borderColor: var(--grayLight);
  background-color: white;
}

.input {
  width: 100%;
  margin: 0;
  overflow: visible;
  font-size: 100%;
  font-family: inherit;
  text-transform: none;
  padding: var(--input-paddingV) var(--input-paddingH);
  border-radius: var(--borderRadiusHalf);
  border: var(--input-borderWidth) solid var(--input-borderColor);
  background: none;
}

.input:focus {
  outline: none;
  border-color: var(--gray);
}

.input[disabled],
.input[readonly] {
  opacity: 0.4;
  cursor: not-allowed;
}

.root__isStatic .input[disabled],
.root__isStatic .input[readonly] {
  opacity: 1;
  cursor: inherit;
}

.input__hasLeading {
  padding-left: calc(var(--input-paddingH) / 2 + var(--leading-width));
}

.root__default .input:hover:not(:focus) {
  border-color: var(--grayLight);
}

.root__isStatic .input:hover:not(:focus) {
  border-color: transparent;
}

.root__noHighlight .input:hover:not(:focus),
.root__noHighlight .input:focus {
  border-color: var(--input-borderColor);
}

/* Must be exactly as wide as `.input` */
.ghost {
  height: 0;
  overflow-y: hidden;
  margin: 0 calc(var(--input-borderWidth) * 2);
  padding: 0 var(--input-paddingH);
}

.ghost__hasLeading {
  padding-left: calc(var(--input-paddingH) / 2 + var(--leading-width));
}

.leading {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--leading-width);
  height: 100%;
}

.spinner {
  position: absolute;
  top: 0;
  right: var(--spacingHalf);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.spinner > * {
  color: var(--gray);
  animation: fadeIn 200ms ease-in 100ms forwards, spin 1.5s linear infinite;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
