.root {
  position: relative;

  --size: 6px;
  --offset: 4px;
}

.root.dirty:before {
  display: inline-block;
  position: absolute;
  content: '';
  width: var(--size);
  height: var(--size);
  top: calc(0px - (var(--offset) / 2));
  right: calc(0px - var(--size) - var(--offset));
  border-radius: 50%;
  background-color: var(--accent);
  opacity: 0.6;
}

.standaloneDirtyDot {
  --size: 6px;

  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  background-color: var(--accent);
}
