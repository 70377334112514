.root {
  margin: var(--spacing) 0;
}

.standaloneInsertButton {
  padding: var(--spacingDouble) 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -60px;
}
