.root {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
}

.floatingMenu {
  position: absolute;
  top: var(--spacing);
  right: var(--spacing);
  display: flex;
}

.floatingMenu_item:not(:last-child) {
  margin-right: var(--spacingHalf);
}

.chatStateError {
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--spacingDouble);
  background-color: var(--surfaceDanger);
}

.chatStateError_icon {
  color: var(--danger);
}

.chatStateError_message {
  flex: 1;
  color: var(--danger);
  margin: 0 var(--spacing);
}

.chatStateError_restartButton {
  flex-shrink: 0;
}

.innerRoot {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: var(--chatBackground);
}

.loading {
  background-color: var(--chatBackground);
}

.maxScore {
  display: inline-flex;

  --status-size: 11px; /* Trial and error */
}

.maxScore_status {
  flex-shrink: 0;
  width: var(--status-size);
  margin-left: var(--spacingHalf);
}

.maxScore_error {
  width: var(--status-size);
  height: var(--status-size);
  color: var(--danger);
}

.maxScore_costly {
  width: var(--status-size);
  height: var(--status-size);
  color: orange;
}

.maxScore_spinner {
  width: var(--status-size);
  height: var(--status-size);
  color: var(--gray);
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}
