.root {
  display: flex;
  width: 100%;
  height: 100%;

  --startColor: #1692ef;
  --pauseColor: #ea7d00;
  --terminalColor: #4fbd00;
}

.prompts {
  flex: 1;
  overflow-y: auto;
  padding-top: var(--spacing3by2);
  padding-bottom: 32px; /* Trial and error */
  background-color: var(--surfaceLight);
}

.markerBar {
  flex-shrink: 0;
  width: 8px;
}
