.root {
  display: flex;
  align-items: center;
  width: 100%;
  margin: var(--spacingDouble) 0;
}

.root::before, .root::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid var(--surfaceDark);
}

.text {
  margin: 0 var(--spacingDouble);
  color: var(--gray);
  text-transform: uppercase;
}
