.evaluation {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.evaluation_body {
  flex: 1;
}

.messageViewBubble {
  width: 100%;
  max-width: 500px;
  min-height: 80px;
  background-color: var(--surfaceAccentLight);
}

.editButton {
  position: relative;
}

.editButton_icon {
  position: absolute;
  top: -4px; /* Trial and error */
  left: -12px; /* Trial and error */
}
