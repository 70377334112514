.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.logo {
  width: 22px;
  height: 22px;
  color: var(--accent);
}

.body {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form {
  width: min(calc(100% - var(--spacing) * 2), 360px);
}
