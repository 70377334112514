.root {
  overflow-x: hidden;
}

.row {
  display: flex;
  overflow-x: auto;
}

.column {
  flex-shrink: 0;
  min-width: 230px; /* Trial and error */
  margin-right: var(--spacing3by2);
}

.column:last-child {
  flex: 1;
  margin-right: 0;
}

.embedCode {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.embedModeTabs {
  flex-shrink: 0;
  justify-content: flex-start;
  background-color: var(--grayDark);
  border-color: var(--grayDark);
}

.embedModeTab {
  min-height: 0;
  padding-bottom: 2px;
  color: white;
  font-size: 80%;
}
.embedModeTab__isActive {
  border-color: white;
}

pre {
  flex: 1;
  margin: 0;
}

.shareLink {
  position: relative;
  margin-top: var(--spacing3by2);
  padding: var(--spacing);
  padding-top: 24px; /* Trial and error */
  border-radius: var(--borderRadiusHalf);
  background-color: var(--surface);
  overflow-wrap: anywhere;
}

.copyToClipboardButton {
  position: absolute;
  top: var(--spacingHalf);
  right: var(--spacingHalf);
}
