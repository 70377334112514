.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: var(--spacing);
  padding: var(--spacingDouble);
  border: 1px solid var(--surfaceLight);
  border-radius: var(--borderRadius);
  background-color: var(--surfaceLight);
}
.root:first-child {
  margin-top: 0;
}

.root__detached {
  border-color: var(--surfaceDark);
  background-color: white;
}

.dragHandle {
  margin-right: var(--spacing);
}

.dragHandle.hidden {
  margin-right: var(--spacing);
  visibility: hidden;
}

.title {
  flex: 1;
}

.tag {
  background-color: var(--surfaceAccent);
  font-size: 85%;
  padding: var(--spacingHalf) var(--spacing);
  border-radius: var(--borderRadiusHalf);
  margin-right: var(--spacingHalf);
}

.action {
  background-color: var(--surface);
}
