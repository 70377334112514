.tetherElement > * {
  position: static !important;
}

.menuHint {
  padding: var(--spacingHalf);
  color: var(--gray);
  text-align: center;
}

.optionLabelKey {
  flex-shrink: 0;
  min-width: 34px; /* Trial and error */
  margin-right: var(--spacing);
  padding: 2px 4px;
  border-radius: var(--borderRadiusHalf);
  background-color: var(--surfaceAccent);
  text-align: center;
  color: var(--grayDark);
}

.optionLabelPreview {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.spinnerContainer {
  display: flex;
  align-items: center;
  margin-right: 2px;
}

.spinner {
  width: 12px;
  height: 12px;
  color: var(--gray);
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}
