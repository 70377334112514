.root {
  display: flex;
  overflow-x: auto;
}

.stat {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--spacing);
}

.stat_title {
  margin-bottom: var(--spacingHalf);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 85%;
  color: var(--grayDark);
}

.stat_value {
  font-size: 150%;
}

.padding {
  flex: 1;
}