.root {
  display: flex;
  flex-direction: row;
  min-width: 0;
}

.dirtyDot {
  min-width: 0;
  margin-left: calc(var(--spacing) + 1px);
  margin-right: calc(
    var(--spacing) + 9px
  ); /* For some exptra space after dot */
}

.name {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 18px;
}

.editButton {
  flex-shrink: 0;
}
