.root {
  position: relative;
  padding: var(--spacingHalf) 0;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}

.root__hovering {
  border-color: hsl(0, 0%, 85%);
}

.answer {
  display: flex;
  align-items: flex-start;
}

.dragHandle {
  margin-right: var(--spacingHalf);
}

.dragHandle path {
  stroke: var(--accent);
}

.leftColumn {
  display: flex;
  align-items: center;
}

.rightColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: var(--spacing);
}

.nextKeyWrapper {
  position: relative;
  display: inline-block;
}

.nextKey__hidden {
  visibility: hidden;
}

.noNextKey {
  position: absolute;
  top: 50%;
  left: var(--spacingDouble);
  right: var(--spacingDouble);
  height: 1px;
  background-color: var(--surface);
}

.noNextKey__hidden {
  visibility: hidden;
}

.topBar {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
}
.topBar > *:not(:last-child) {
  margin-right: var(--spacing);
}

.message {
  display: flex;
  width: 100%;
  /* Top margin is set by trial-and-error */
  margin: 0 0 var(--spacingHalf);
}

.message_left {
  flex: 1;
}

.message_right {
  flex-shrink: 0;
}

.actionMenu {
  z-index: 30;
}

.actionMenuButton {
  color: var(--accent);
}

.bottomBar {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
}

.makeCatchAll {
  display: inline-flex;
  align-items: center;
  padding: var(--spacingHalf) 0;
  color: var(--gray);
}

.makeCatchAll_label {
  margin-left: var(--spacingHalf);
  font-size: 80%;
}
