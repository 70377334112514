.root {
  padding: var(--spacingHalf) var(--spacing);
  border-radius: var(--borderRadiusHalf);
  background-color: var(--surface);
  font-size: 12px;
  color: var(--grayDark);
  cursor: default;
}

.title {
  color: var(--grayDark);
  font-size: 90%;
  font-weight: bold;
}

.name {
  color: var(--primary);
}

.email {
  color: var(--grayDark);
}
