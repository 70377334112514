.inputBar {
  display: flex;
}

.inputBar_input {
  flex: 1;
  margin-right: var(--spacing);
}

.users {
  display: flex;
  flex-direction: column;
  margin-top: var(--spacing3by2);
}

.users_status {
  padding: var(--spacing);
  text-align: center;
  color: var(--gray);
}

.user {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing);
  padding: var(--spacing) var(--spacing3by2);
  border-radius: var(--borderRadiusHalf);
  background-color: var(--surfaceLight);
}
.user:last-child {
  margin-bottom: 0;
}

.user_details {
  flex: 1;
  min-width: 0;
}

.user_email {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: var(--spacingHalf);
}

.user_role {
  color: var(--accent);
  font-size: 90%;
}

.user_actions {
  flex-shrink: 0;
}
.user_actions > * {
  margin-left: var(--spacing);
}
