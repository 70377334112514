.root {
  display: inline-flex;

  /* Set to match height variable in `VariableEditor`.
   * Extracted out here because it is needed in multiple classes below. */
  --itemHeight: 24px;
}

/* Below styles (especially dimensions) must match corresponding elements in
 * `VariableEditor`. */

.brackets {
  margin: 0 2px;
  color: var(--gray);
  cursor: pointer;
}

.inputWrapDummy {
  display: inline-block;
  min-width: 65px;
  height: var(--itemHeight);
  font-size: 85%;
}

.typeChangeButtonDummy {
  width: var(--itemHeight);
  height: var(--itemHeight);
  margin-left: 2px;
}
