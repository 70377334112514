.root {
  position: relative;
}

.editIcon {
  position: absolute;
  display: none;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 50%;
  background-color: hsla(0, 0%, 0%, 0.5);
  color: white;
}

.root:hover .editIcon {
  display: flex;
}
