.root {
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.itemsColumn {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  width: 25%;
  min-width: 300px;
}

.filter {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 46px;
  padding: var(--spacingHalf);
  background-color: var(--surfaceLight);
}

.filter_searchInput {
  flex: 1;
}

.items {
  flex: 1;
  overflow-y: auto;
}

.item {
  display: flex;
  align-items: center;
  padding: var(--spacing3by2) var(--spacing);
  border-bottom: 1px solid var(--surfaceDark);
  background-color: var(--surfaceLight);
  overflow-x: hidden;
}

.item:hover {
  filter: brightness(0.97);
}

.item__selected {
  background-color: var(--surface);
}

.item_title {
  font-size: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.item__selected .item_title {
  color: var(--tertiaryDark);
}

.originJoin {
  display: inline-block;
  margin: 0 1px;
  color: var(--grayDark);
}

.detailsColumn {
  flex: 1;
  background-color: var(--surface);
  overflow: hidden;
}

.itemListFooter {
  display: flex;
  justify-content: center;
  padding: var(--spacing);
}
