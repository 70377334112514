.plan_name {
  font-weight: bold;
  color: var(--primary);
}

.loadingStatus {
  text-align: center;
  color: var(--gray);
}

.usage {
  margin-top: var(--spacing);
}

.usage_actual {
  font-weight: bold;
  color: var(--success);
}

.usage_actual__crossed {
  color: var(--danger);
}

.usage_actual__nearing {
  color: var(--tertiary);
}

.limitCrossedMessage {
  margin-top: var(--spacing);
  font-size: 95%;
  color: var(--grayDark);
}

.pricingPlansLinkWrapper {
  margin-top: var(--spacing);
}
