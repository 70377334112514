.root {
  display: flex;
  flex-direction: column;
  margin-top: var(--spacing);
}

.root.__received {
  align-items: flex-start;
  margin-left: 36px; /* Trial and error */
  padding-left: var(--spacing3by2);
  border-left: 3px solid var(--grayLight);
}

.root.__sent {
  align-items: flex-end;
  padding-right: var(--spacing3by2);
  border-right: 3px solid var(--grayLight);
}

.body {
  flex: 1;
}

.actions {
  flex-shrink: 0;
  display: flex;
}

.actions:not(:first-child) {
  margin-top: var(--spacing);
}

.actions > *:not(:last-child) {
  margin-right: var(--spacingDouble);
}
