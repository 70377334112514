/* Rendered within ChatView, so beware of clashing CSS vars */

.inputForm {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: var(--spacing);
  border-radius: var(--borderRadius);
}

.editButton {
  position: relative;
}

.editButton_icon {
  position: absolute;
  top: -4px; /* Trial and error */
  right: -12px; /* Trial and error */
}

.statusIcon__hidden {
  visibility: hidden;
}

/*** Feedback form ***/

.inputForm.__staticFeedback {
  background-color: var(--surface);
}

.inputForm.__editorFeedback {
  width: 100%;
  max-width: 500px;
  background-color: var(--surfaceAccentLight);
}

.feedback {
  flex: 1;
}

.inputForm.__staticFeedback .feedback {
  max-height: 300px;
  overflow-y: auto;
}

.inputForm.__editorFeedback .feedback {
  min-height: 80px;
}

/*** SuperScore form ***/

.inputForm.__staticSuperScore {
  background-color: var(--surface);
}

.inputForm.__editorSuperScore {
  background-color: var(--surfaceAccentLight);
}

.superScore {
  text-align: right;
  font-size: 95%;
  color: var(--grayDark);
}

.superScore_Input {
  width: 80px;
}
