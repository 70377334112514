.root {
  --minHeight: 50px; /* Match TopBar */
  --borderWidth: 1px;
  --minTabHeight: calc(var(--minHeight) - var(--borderWidth));

  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items:stretch;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 var(--spacing);
  border-bottom: var(--borderWidth) solid var(--surfaceDark);
}

.root__isInTopBar {
  --minHeight: 28px;

  border-bottom: none;
}

.tab {
  flex-shrink: 0;
  min-height: var(--minTabHeight);
  margin: 0 var(--spacing3by2);
  padding-top: 6px; /* Bring text a bit lower. Trial and error. */
  border-radius: 0;
  border-bottom: 2px solid transparent;
  color: var(--gray);
  font-size: 14px;
  text-transform: uppercase;
}

.tab__isActive {
  border-color: var(--primary);
  color: var(--primary);
}

.trailing {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  min-height: var(--minTabHeight);
}
