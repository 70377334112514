.message {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  width: 120px; /* Trial and error */
  margin-bottom: var(--spacing);
}

.iframe {
  width: 100%;
  height: 100%;
  display: block;
  border: none;
}
