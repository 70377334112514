.root {
  --borderRadius: var(--borderRadiusHalf);
  --backgroundColor: var(--surfaceAccent);

  display: flex;
  align-items: center;
  border-radius: var(--borderRadius);
  background-color: var(--backgroundColor);
}

.label {
  margin: 0 3px;
  flex-shrink: 0;
  font-size: 70%;
}

.body {
  flex: 1;
  min-width: 65px;
}

/* Styles (especially dimensions) must match those in `PromptKeySelect` */

.static {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 24px;
  border-radius: var(--borderRadius);
  background-color: var(--backgroundColor);
}

.static_key {
  padding: 2px 8px 2px 11px; /* Left padding especially by trial and error */
  font-size: 85%;
}

.static__shouldScroll > .static_key {
  color: black;
  text-decoration: underline;
}
