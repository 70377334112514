.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.logo {
  width: 22px;
  height: 22px;
  color: var(--accent);
}

.tabs {
  flex-shrink: 0;
}

.body {
  flex: 1;
  overflow-y: auto;
}

.list {
  padding: 16px var(--spacing) 0;
}

@media (min-width: 768px) {
  .list {
    width: 768px;
    margin: 0 auto;
    padding: 24px var(--spacing) 0;
  }
}

.itemAction {
  background-color: var(--surface);
}

.formActions {
  margin-top: var(--spacing3by2);
}
