.root {
  display: inline-block;
}

.dropdownPart {
  flex: 0;
}

.menu {
  z-index: 30;
}
