.markerBar {
  height: 100%;
  /* We add some padding above and below the 'track' on which notches appear,
   * so that we don't have notches showing up too close to the top or bottom.
   */
  padding: 16px 0;
  background-color: var(--surfaceLight);
}

.markerBar_track {
  position: relative;
  width: 100%;
  height: 100%;
}

.markerBar_notch {
  position: absolute;
  width: 100%;
  height: 3px;
}

.markerContainer {
  position: relative;
}
