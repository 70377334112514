.line {
  flex: 1;
  min-width: 12px;
  margin: var(--spacing) var(--spacingHalf);
  border-bottom: 1px solid var(--grayLight);
}

.row {
  display: flex;
  align-items: center;
}

.title {
  flex-shrink: 0;
  overflow: hidden;
  margin: var(--spacing3by4) calc(var(--spacingHalf) / 2);
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: uppercase;
  color: var(--gray);
  font-size: 80%;
}
