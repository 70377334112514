.root {
  flex-shrink: 0;
  z-index: 10;
  box-shadow: var(--boxShadow3);
}

.bar {
  display: flex;
  min-height: 50px; /* Match Tabs */
  padding: 0 var(--spacing);
}

.left {
  flex: 1;
  display: flex;
  align-items: center;
  overflow-x: hidden;
}

.leading {
  flex-shrink: 0;
  margin-right: var(--spacing3by2);
}

.titleString {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--primary);
  font-size: 18px;
}

.titleComponent {
  flex: 1;
  min-width: 0;
}

.center {
  flex-shrink: 0;
  display: flex;
}

@media (max-width: 768px) {
  .center {
    display: none;
  }
}

.centerMobile {
  display: flex;
}

.actions {
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

@media (min-width: 768px) {
  .centerMobile {
    display: none;
  }

  .actions__hasCenter {
    flex: 1;
    justify-content: flex-end;
  }
}

.actions > * {
  margin-left: var(--spacing);
}
