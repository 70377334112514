.root {
  position: relative;
  padding: var(--spacing) 0;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}

.root__hovering {
  border-color: hsl(0, 0%, 85%);
}

.messageItem {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.dragHandle {
  flex-shrink: 0;
  margin-top: 4px; /* Trial and error */
  margin-right: var(--spacing); /* Trial and error */
}

.dragHandle path {
  stroke: var(--gray);
}

.editor {
  flex: 1;
}

.editor_alt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: var(--spacingHalf) 0;
  font-size: 80%;
  color: var(--gray);
}

.actionMenu {
  z-index: 30;
}

.actionMenuButton {
  color: var(--grayLight);
}
