.root {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
}

.configColumn {
  width: 270px;
  height: 100%;
  flex: none;
  border-right: 1px solid var(--gray);
}

/*
 * Calculation for max-width (V):
 * Let A = .promptsColumn::flex
 *     B = .previewColumn::flex
 *     C = .promptsColumn::minWidth
 *     D = .configColumn::width
 * Then,
 *     V / (A+B) * A = C + D
 *  => V = (C+D) / A * (A+B)
 */
@media (max-width: 900px) {
  .configColumn {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    box-shadow: var(--boxShadow4);
    border: none;
    background-color: white;
  }
}

.promptsColumn {
  flex: 1;
  min-width: 300px;
  max-width: 600px;
  height: 100%;
}

.previewColumn {
  flex: 1;
  min-width: 300px;
  height: 100%;
}

/* Set maxWidth = sum of min widths of prompts and preview columns - 1 */
@media (max-width: 599px) {
  .previewColumn {
    display: none;
  }
}
