.root {
  display: flex;
  align-items: center;
}

.menuBottomBar {
  margin-bottom: var(--spacingHalf);
}

.option {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.languageItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing) var(--spacing3by2);
}
.languageItem:not(:last-of-type) {
  border-bottom: 1px solid var(--surfaceDark);
}

.noLanguagesHint {
  padding: var(--spacing3by2) var(--spacing);
  text-align: center;
  color: var(--gray);
}
