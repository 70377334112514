.root {
  position: absolute;
  z-index: 5;
  opacity: 0;
  animation: fadeIn 100ms ease-in 100ms forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.7;
  }
}

.button {
  display: flex;
  margin-top: -2px;
  margin-left: 60px;
  box-shadow: var(--boxShadow4);
  font-size: 75%;
  padding: 2px var(--spacing) 2px var(--spacingHalf);
  background-color: var(--grayDark);
  color: white;
}

.button__isAbove {
  margin-top: -18px;
}
