.swatch {
  width: 50px;
  height: 28px;
  border-radius: var(--borderRadiusHalf);
  cursor: pointer;
  border: 2px solid transparent;
}

.swatch:hover,
.swatch__isOpen {
  border-color: var(--surfaceAccentDark);
}

.picker {
  padding: var(--spacing);
  border: 1px solid var(--surfaceDark);
  border-radius: var(--borderRadius);
  box-shadow: var(--boxShadow4);
  background-color: white;
}

.picker_input {
  display: flex;
  margin-top: var(--spacing);
  color: var(--grayDark);
}

.picker_input > span {
  flex-shrink: 0;
  margin-right: var(--spacingHalf);
}

.picker_input > input {
  flex: 1;
  padding: var(--spacingHalf);
  border: 1px solid var(--surfaceDark);
  border-radius: var(--borderRadiusHalf);
}
