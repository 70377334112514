.root {
  padding: 0 var(--spacing) 14px;
}

.card {
  position: relative;
  padding: var(--spacing3by2);
  border: 1px solid transparent;
  border-radius: var(--borderRadius);
  box-shadow: var(--boxShadow2);
  background-color: white;
}

.root__isPreviewing .card {
  border-color: var(--tertiary);
  box-shadow: var(--boxShadow4);
}

.leftArrow {
  --arrowWidth: 10px;
  position: absolute;
  top: calc(50% - var(--arrowWidth));
  left: calc(-1 * var(--arrowWidth));
  width: 0;
  height: 0;
  border-top: var(--arrowWidth) solid transparent;
  border-bottom: var(--arrowWidth) solid transparent;
  border-right: var(--arrowWidth) solid var(--tertiary);
}

.topBar {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing);
}

.dragHandle {
  margin-right: var(--spacing);
}

.dragHandle__hidden {
  visibility: hidden;
}

.icon {
  width: 22px;
  margin-right: var(--spacingHalf);
  fill: var(--gray);
}

.root__isPreviewing .icon {
  fill: var(--tertiary);
}

.name {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.name_button {
  flex-shrink: 0;
  font-weight: bold;
  color: var(--gray);
}

.root__isPreviewing .name_button {
  color: var(--tertiary);
}

.name_suffix {
  margin-left: var(--spacing);
  font-size: 70%;
  font-weight: bold;
  text-transform: uppercase;
}
.name_suffix__start {
  color: var(--startColor);
}
.name_suffix__pause {
  color: var(--pauseColor);
}
.name_suffix__terminal {
  color: var(--terminalColor);
}
.name_suffix__i18nMissing {
  color: var(--i18n);
}

.name_definitionErrors {
  margin-left: var(--spacing);
  color: var(--danger);
  font-size: 90%;
}

.definitionErrorsContainer {
  max-width: 400px;
  z-index: 40;
}

.definitionErrorsContent {
  padding: var(--spacingHalf) var(--spacing);
  box-shadow: var(--boxShadow4);
  border-radius: var(--borderRadiusHalf);
  border: 1px solid var(--danger);
  background-color: white;
}

.definitionErrorsContent > ul {
  margin: 0;
  margin-left: 16px; /* Trial and error */
  padding: 0;
}

.actionMenu {
  z-index: 30;
}

.actionMenuButton {
  color: var(--grayDark);
}

.message {
  margin-bottom: var(--spacingHalf);
}

.answers {
  margin-bottom: var(--spacingHalf);
}

.nextKey {
  display: inline-block;
}

.altMessages {
  margin: var(--spacing3by2) 0;
  background-color: var(--surfaceAccentLight);
}
