.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: hsla(0, 0%, 0%, 0.4);
  z-index: 30;
  opacity: 0;
  transition: 200ms ease-in-out;
  transition-property: opacity;
}

.overlayAfterOpen {
  opacity: 1;
}

.overlayBeforeClose {
  opacity: 0;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -70%);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow-x: auto;
  overflow-scrolling: touch;
  z-index: 31;
  opacity: 0;
  outline: none;
  border-radius: var(--borderRadius);
  background-color: white;
  transition: 200ms ease-in-out;
  transition-property: opacity, transform;
}

.contentAfterOpen {
  transform: translate(-50%, -50%);
  opacity: 1;
}

.contentBeforeClose {
  transform: translate(-50%, -70%);
  opacity: 0;
}

.header {
  flex-shrink: 0;
  display: flex;
  justify-content: flex-end;
  padding: var(--spacing3by2) var(--spacingDouble);
  border-bottom: 1px solid var(--surfaceDark);
}

.header_title {
  flex: 1;
  font-weight: bold;
  color: var(--grayDark);
}

.header_titleAction {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  margin: 0 var(--spacing);
}

.header_close {
  flex: none;
  color: var(--gray);
}

.body {
  flex: 1;
  overflow-x: auto;
  padding: var(--spacingDouble);
}

.body__noSpacing {
  padding: 0;
}

/* Utility class for showing actions at the bottom of a modal */
.actions {
  display: flex;
  margin-top: var(--spacingDouble);
}

.actions > *:not(:last-child) {
  margin-right: var(--spacing);
}
