.root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.bigPicture {
  flex-shrink: 0;
}

.analytics {
  flex: 1;
  overflow-y: hidden;
}
