.root {
}

.apiSelect {
  display: flex;
  align-items: center;
}

.apiSelect_label {
  flex-shrink: 0;
  margin-right: var(--spacing);
}

.apiSelect_select {
  flex: 1;
}

.configureButton {
  display: flex;
  margin: var(--spacingHalf) 0;
  justify-content: flex-end;
}
