.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.title {
  display: flex;
  align-items: center;
}

.flowSequence {
  flex: 1;
  overflow-y: hidden;
  margin-top: var(--spacingDouble);
}

@media (min-width: 768px) {
  .flowSequence {
    width: 768px;
    margin-left: auto;
    margin-right: auto;
  }
}
