.popoverContainer {
  z-index: 11;
  margin-top: var(--spacingHalf);
}

.menu {
  max-width: 300px;
  max-height: calc(100vh - 50px); /* Trial and error */
  overflow: auto;
}
