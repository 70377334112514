.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.filters {
  display: inline-flex;
  align-items: center;
}

.tabs {
  flex-shrink: 0;
}

.body {
  flex: 1;
  overflow-y: hidden;
}
