.root {
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.itemsColumn {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  width: 25%;
  min-width: 300px;
}

.filter {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 46px;
  padding: var(--spacingHalf);
  background-color: var(--surfaceLight);
}

.filter > *:not(:last-child) {
  margin-right: var(--spacing);
}

.filter_searchInput {
  flex: 1;
}

.filter_toggleButton {
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--surface);
}

.filter_toggleButton__active {
  box-shadow: var(--boxShadow3);
  background-color: var(--surfaceSuccess);
}

.topBar {
  display: flex;
  justify-content: flex-end;
  padding: var(--spacingHalf);
  border-top: 1px solid var(--surface);
  border-bottom: 1px solid var(--surface);
  background-color: var(--surfaceLight);
}

.dlPopover {
  max-width: 300px;
  z-index: 30;
  padding: var(--spacingDouble);
  box-shadow: var(--boxShadow3);
  border-radius: var(--borderRadius);
  background-color: white;
}

.dlPopover_body {
  display: flex;
  flex-direction: column;
}

.dlPopover_title {
  margin-bottom: var(--spacingHalf);
  color: var(--grayDark);
}

.dlPopover_hint {
  margin-bottom: var(--spacing);
  color: var(--gray);
  font-size: 85%;
}
.dlPopover_hint_experimental {
  font-size: 80%;
  color: var(--danger);
}

.dlPopover_button {
  align-self: center;
  margin-top: var(--spacing);
  margin-bottom: var(--spacingDouble);
}
.dlPopover_button:last-child {
  margin-bottom: 0;
}

.items {
  flex: 1;
  overflow-y: auto;
}

.item {
  display: flex;
  align-items: center;
  padding: var(--spacing);
  border-bottom: 1px solid var(--surfaceDark);
  background-color: var(--surfaceLight);
  overflow-x: hidden;
}

.item:hover {
  filter: brightness(0.97);
}

.item__selected {
  background-color: var(--surface);
}

.item__softDeleted {
  filter: opacity(0.2);
}

.item_info {
  flex: 1;
  overflow-x: hidden;
}

.item_info_title {
  margin-bottom: var(--spacing);
  font-size: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.item__selected .item_info_title {
  color: var(--tertiaryDark);
}

.item_info_subtitle {
  margin: 0;
  font-size: 12px;
  color: var(--grayDark);
}

.item_adminInputsIcon__hidden {
  visibility: hidden;
}

.item_statsIcon {
  margin-left: var(--spacingHalf);
  background-color: var(--surface);
  color: var(--grayDark);
}

.item__selected .item_statsIcon {
  background-color: var(--surfaceDark);
}

.chatViewColumn {
  flex: 1;
  min-width: 300px;
  background-color: var(--surface);
}

.itemListFooter {
  display: flex;
  justify-content: center;
  padding: var(--spacing);
}
