.AutoResponder_root___44260 {
  position: absolute;
  right: 0;
  bottom: -24px; /* Trial and error */
  display: flex;
  align-items: center;
}

.AutoResponder_keyboardHint___44260 {
  margin-right: var(--spacing);
}

.utils_fullWidth___true___49ce5 {
  width: 100%;
}

@media screen and (max-width: 1199px) {
  .utils_fullWidth___lgDown___49ce5 {
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .utils_fullWidth___mdDown___49ce5 {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .utils_fullWidth___sm___49ce5 {
    width: 100%;
  }
}

.Button_root___a19cd {
  height: var(--Button-height);
  padding: 0 var(--Button-paddingH);
}

.Button_root__sm___a19cd {
  height: var(--Button__sm-height);
}

.KeyboardHint_root___8e8d6 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.KeyboardHint_icon___8e8d6 {
  margin-right: var(--spacing);
  color: var(--KeyboardHint-color);
  font-size: var(--KeyboardHint-fontSize);
}

.KeyboardHint_text___8e8d6 {
  color: var(--KeyboardHint-color);
  font-size: var(--KeyboardHint-fontSize);
}

.DevNotification_root___544a6 {
  display: flex;
  align-items: center;
  max-width: 300px;
  margin: 12px;
  padding: 6px 8px;
  border: 1px solid gray;
  border-radius: var(--borderRadius);
  background-color: #fff6c9;
  color: gray;
  font-size: 90%;
}

.DevNotification_icon___544a6 {
  margin-right: 6px;
  color: #2525a5;
}

.DevNotification_message___544a6 {
  flex: 1;
}

.ReactMarkdown_root___b3df5 > *:first-child {
  margin-top: 0;
}

.ReactMarkdown_root___b3df5 > *:last-child {
  margin-bottom: 0;
}

.Choices_keyboardHint___9ac55 {
  margin-bottom: var(--spacing);
}

.ChoicesSend_root___9f8ae {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}

.ChoicesSend_text___9f8ae {
  margin-right: var(--ChoicesSend_text_marginR);
  font-size: var(--ChoicesSend_text-fontSize);
}

.ChoicesSend_button___9f8ae {
}

.InputField_root___dcabe {
  height: var(--InputField-height);
  padding: 0 var(--InputField-paddingH);
}

.TextareaField_root___9fee5 {
  resize: vertical;
  padding: var(--TextareaField-paddingH) var(--TextareaField-paddingH);
}

@media screen and (min-width: 768px) {
  /**
   * Note the `min`. This is needed because even though the screen width may be
   * more than 768px, the chat view could be occupying much less than the whole
   * width, small enough that the input could start to overflow horizontally.
   */
  .HtmlInput_root___dd52d {
    min-width: min(100%, var(--HtmlInput-minWidth-mdUp));
  }
}

@media screen and (max-width: 767px) {
  .HtmlInput_root___dd52d {
    width: 100%;
  }
}

.HtmlInput_form___dd52d {
  display: flex;
}

.HtmlInput_form_separator___dd52d {
  width: var(--HtmlInput_form_separator-size);
  height: var(--HtmlInput_form_separator-size);
}

.HtmlInput_keyboardHint___dd52d {
  margin-bottom: var(--spacing);
}

@media screen and (min-width: 768px) {
  .HtmlTextarea_root___30c00 {
    min-width: var(--HtmlTextarea-minWidth-mdUp);
  }
}

@media screen and (max-width: 767px) {
  .HtmlTextarea_root___30c00 {
    width: 100%;
  }
}

.HtmlTextarea_form___30c00 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.HtmlTextarea_form_separator___30c00 {
  width: var(--HtmlTextarea_form_separator-size);
  height: var(--HtmlTextarea_form_separator-size);
}

.LargeChoice_root___04324 {
  display: flex;
  flex-direction: column;
  width: var(--LargeChoice-width);
  box-shadow: var(--LargeChoice-boxShadow);
  border-radius: var(--LargeChoice-borderRadius);
  border: 1px solid var(--LargeChoice_body-backgroundColor);
}

.LargeChoice_root__isSelected___04324 {
  filter: brightness(75%);
}

.LargeChoice_root__isHighlighted___04324 {
  border-color: var(--LargeChoice-highlightColor);
}

.LargeChoice_body___04324 {
  flex-grow: 1;
  min-height: 100px;
  padding: var(--LargeChoice_body-paddingV) var(--LargeChoice_body-paddingH);
  border-radius: var(--LargeChoice-borderRadius) var(--LargeChoice-borderRadius)
    0 0;
  background-color: var(--LargeChoice_body-backgroundColor);
}

.LargeChoice_body___04324 > *:first-child {
  margin-top: 0;
}

.LargeChoice_body___04324 > *:last-child {
  margin-bottom: 0;
}

.LargeChoice_body___04324 img {
  display: block;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.LargeChoice_root__isReadonly___04324 .LargeChoice_body___04324 {
  border-radius: var(--LargeChoice-borderRadius);
}

.LargeChoice_action___04324 {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  padding: var(--LargeChoice_action-paddingV) 0;
  border-radius: 0 0 var(--LargeChoice-borderRadius)
    var(--LargeChoice-borderRadius);
  background-color: var(--LargeChoice_action-backgroundColor);
  text-align: center;
  color: var(--LargeChoice_action-color);
  cursor: pointer;
}

.LargeChoice_action___04324:hover {
  filter: brightness(85%);
}

.LargeChoice_action_index___04324 {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--LargeChoice_action_index-size);
  height: var(--LargeChoice_action_index-size);
  margin-left: var(--LargeChoice_action_index-marginL);
  border-radius: var(--LargeChoice_action_index-borderRadius);
  border: 1px solid var(--surfaceDark);
  background-color: var(--surfaceLight);
  color: var(--gray);
  font-size: 90%;
  font-weight: bold;
}

.LargeChoice_root__isHighlighted___04324 .LargeChoice_action_index___04324 {
  border-color: var(--LargeChoice-highlightColor);
  color: var(--LargeChoice-highlightColor);
}

.LargeChoice_action_label___04324 {
  flex-grow: 1;
  align-items: center;
  font-size: 75%;
  text-transform: uppercase;
}

.LargeChoice_action_balancer___04324 {
  width: calc(
    var(--LargeChoice_action_index-size) +
      var(--LargeChoice_action_index-marginL)
  );
}

.LargeChoices_root___bab13 {
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.LargeChoices_items___bab13 {
  display: flex;
  overflow-x: auto;
}

.LargeChoices_item___bab13 {
  padding-right: var(--LargeChoices_item-paddingR);
  padding-bottom: var(--LargeChoices_item-paddingB);
}

.LargeChoices_item___bab13:last-child {
  padding-right: 0;
}

.SmallChoice_root___20fa7 {
  position: relative;
  display: inline-block;
  padding: var(--SmallChoice-paddingV) var(--SmallChoice-paddingH);
  box-shadow: var(--SmallChoice-boxShadow);
  border-radius: var(--SmallChoice-borderRadius);
  border: 2px solid var(--SmallChoice-backgroundColor);
  background-color: var(--SmallChoice-backgroundColor);
  color: var(--SmallChoice-color);
  cursor: pointer;
}

.SmallChoice_root___20fa7:hover {
  filter: brightness(85%);
}

.SmallChoice_root__isReadonly___20fa7 {
  cursor: default;
}

.SmallChoice_root__isReadonly___20fa7:hover {
  filter: none;
}

.SmallChoice_root__isSelected___20fa7 {
  filter: brightness(75%);
}

.SmallChoice_root__isSelected___20fa7:hover {
  filter: brightness(75%);
}

.SmallChoice_root__isHighlighted___20fa7 {
  box-shadow: 0 0 2px 1px var(--SmallChoice-highlightColor);
  border-color: white;
}

.SmallChoice_selectedIcon___20fa7 {
  position: absolute;
  top: -2px; /* Trial and error */
  right: 4px; /* Trial and error */
}

.SmallChoices_root___2934a {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.SmallChoices_items___2934a {
  display: flex;
  flex-wrap: wrap;
}

.SmallChoices_item___2934a {
  padding-right: var(--SmallChoices_item-paddingR);
  padding-bottom: var(--SmallChoices_item-paddingB);
}

.SmallChoices_item___2934a:last-child {
  padding-right: 0;
}

.StackedChoice_root___efc6c {
  display: flex;
  align-items: center;
  max-width: var(--StackedChoice-maxWidth);
  padding: var(--StackedChoice-paddingV) var(--StackedChoice-paddingH);
  box-shadow: var(--StackedChoice-boxShadow);
  border-radius: var(--StackedChoice-borderRadius);
  border: 1px solid var(--StackedChoice-backgroundColor);
  background-color: var(--StackedChoice-backgroundColor);
}

.StackedChoice_root__isReadonly___efc6c {
}

.StackedChoice_root__isSelected___efc6c {
  filter: brightness(75%);
}

.StackedChoice_root__isSelected___efc6c:hover {
  filter: brightness(75%);
}

.StackedChoice_root__isHighlighted___efc6c {
  border-color: var(--StackedChoice-highlightColor);
}

.StackedChoice_body___efc6c {
  flex-grow: 1;
}

.StackedChoice_body___efc6c > *:first-child {
  margin-top: 0;
}

.StackedChoice_body___efc6c > *:last-child {
  margin-bottom: 0;
}

.StackedChoice_body___efc6c img {
  display: block;
  max-width: 100%;
  max-height: var(--StackedChoice_body_img-maxHeight);
  margin-left: auto;
  margin-right: auto;
}

.StackedChoice_index___efc6c {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--StackedChoice_index-size);
  height: var(--StackedChoice_index-size);
  margin-right: var(--StackedChoice-spacing);
  border-radius: var(--StackedChoice_index-borderRadius);
  border: 1px solid var(--StackedChoice_index-color);
  color: var(--StackedChoice_index-color);
  font-size: 90%;
  font-weight: bold;
}

.StackedChoice_root__isHighlighted___efc6c .StackedChoice_index___efc6c {
  border-color: var(--StackedChoice-highlightColor);
  color: var(--StackedChoice-highlightColor);
}

.StackedChoice_action___efc6c {
  flex-shrink: 0;
  width: var(--StackedChoice_action-width);
  margin-left: var(--StackedChoice-spacing);
  padding: var(--StackedChoice_action-paddingV)
    var(--StackedChoice_action-paddingH);
  border-radius: 4px;
  background-color: var(--StackedChoice_action-backgroundColor);
  text-align: center;
  color: var(--StackedChoice_action-color);
  font-size: 75%;
  text-transform: uppercase;
  cursor: pointer;
}

.StackedChoice_action___efc6c:hover {
  filter: brightness(85%);
}

.StackedChoices_root___f71a9 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

/* sm */
@media screen and (max-width: 767px) {
  .StackedChoices_root___f71a9 {
    align-items: stretch;
    width: 100%;
  }
}

.StackedChoices_items___f71a9 {
  display: flex;
  flex-direction: column;
}

.StackedChoices_item___f71a9 {
  padding-bottom: var(--StackedChoices_item-paddingB);
}

.AwsFileUpload_form___b7f86 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.AwsFileUpload_form_separator___b7f86 {
  width: var(--AwsImageUpload_form_separator-size);
  height: var(--AwsImageUpload_form_separator-size);
}

.AwsFileUpload_dropzone___b7f86 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: var(--AwsImageUpload_dropzone-marginB);
  padding: var(--AwsImageUpload_dropzone-padding);
  box-shadow: var(--AwsImageUpload_dropzone-boxShadow);
  border-radius: var(--AwsImageUpload_dropzone-borderRadius);
  background-color: var(--AwsImageUpload_dropzone-backgroundColor);
}

.AwsFileUpload_dropzoneItem___b7f86 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: var(--AwsImageUpload_dropzoneItem-width);
  height: var(--AwsImageUpload_dropzoneItem-height);
  margin: var(--AwsImageUpload_dropzoneItem-margin);
  box-shadow: var(--AwsImageUpload_dropzoneItem-boxShadow);
  background-color: var(--AwsImageUpload_dropzoneItem-backgroundColor);
  text-align: center;
}

.ril__captionContent {
  text-align: center;
  width: 100%;
}

.FileThumbnail_root___3ce47 {
  position: relative;
  border-radius: 4px;
}

.FileThumbnail_root___3ce47 p {
  margin: var(--spacingHalf) 0;
}

.FileThumbnail_preview___3ce47 {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.FileThumbnail_actions___3ce47 {
  position: absolute;
  top: 2px;
  right: 2px;
}

.FileThumbnail_action___3ce47 {
  height: auto;
  padding: 2px;
  opacity: 0.8;
  cursor: pointer;
}

.FileThumbnail_progressBar___3ce47 {
  position: absolute;
  bottom: 0;
  width: 90%;
}

.InputView_root___86165 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

/* Originally, we were sliding up. However, that led to a weird bug where, in
 * the DiglogForm website, it caused an extra scrollbar to appear in the modal.
 * Also, we suspect that sliding horizontally might lead to some similar issue,
 * i.e. the possibly appearance of an unwanted horizontal scrollbar. Hence, we
 * are choosing to slide down as the safest option.
 */
@keyframes InputView_slideDown___86165 {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.InputView_root__animated___86165.InputView_root___86165 {
  animation-name: InputView_slideDown___86165;
  animation-delay: 500ms;
  animation-duration: 500ms;
  animation-fill-mode: backwards;
}

.InputView_externalMessage___86165 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: var(--spacing) 0;
}

.InputView_externalMessage_pending___86165 {
  display: flex;
  align-items: center;
  color: var(--gray);
}

.InputView_externalMessage_pendingIcon___86165 {
  margin-right: var(--spacing);
}

.useBaseCssVariables_variables___c7c74 {
  --spacing: 8px;
  --borderRadius: 8px;

  --elevation1: 3px;
  --elevation2: 5px;
  --elevation3: 8px;
  --elevation4: 13px;
  --elevationColor: hsla(0, 0%, 0%, 0.1);

  --boxShadow1: 0 0 var(--elevation1) 0 var(--elevationColor);
  --boxShadow2: 0 0 var(--elevation2) 0 var(--elevationColor);
  --boxShadow3: 0 0 var(--elevation3) 0 var(--elevationColor);
  --boxShadow4: 0 0 var(--elevation4) 0 var(--elevationColor);

  --gray: hsl(0, 0%, 50%);
  --grayLight: hsl(0, 0%, 70%);

  --primary: hsl(208, 84%, 10%);
  --accent: hsl(180, 58%, 46%);
  --highlight: hsl(225, 74%, 45%);

  --surface: hsl(0, 0%, 94%);
  --surfaceLight: hsl(0, 0%, 98%);
  --surfaceDark: hsl(0, 0%, 90%);

  /**
   * Set programmatically whenever the user manually sets the `--primary` or
   * `--accent` variables (unless user manually provides values for these too.
   * The value given below match those values that would have been calculated
   * programmatically for the above `--primary` and `--accent`.
   */
  --onPrimary: #ffffff;
  --onAccent: #000000;
  --surfacePrimary: hsl(206, 10%, 86%);
  --surfaceAccent: hsl(180, 51%, 92%);

  /* Derived from the variables above */
  --spacingHalf: calc(var(--spacing) / 2);
  --spacing3by2: calc(var(--spacing) * 1.5);
  --spacingDouble: calc(var(--spacing) * 2);
  --borderRadiusHalf: calc(var(--borderRadius) / 2);
}

/* We separate this out from `root`. If you ever want to render some chatbot
 * components outside of the ChatView DOM tree (such as in a modal), you can
 * wrap them in a div with this class,
 * Also remember to add the `.root` class from `BaseCssVariables.module.css`,
 * since it has all the basic CSS variables that all these styles depend on.
 */
.ChatView_rootCommon___e93f0 {
  /*
   * Avatar
   */
  --Avatar-size: 30px;
  --Avatar-marginR: var(--spacing);
  --Avatar-backgroundColor: var(--primary);

  /*
   * AwsImageUpload
   */
  --AwsImageUpload_dropzone-marginB: var(--spacing);
  --AwsImageUpload_dropzone-padding: var(--spacing);
  --AwsImageUpload_dropzone-boxShadow: var(--boxShadow1);
  --AwsImageUpload_dropzone-borderRadius: var(--spacing);
  --AwsImageUpload_dropzone-backgroundColor: var(--surfaceLight);
  --AwsImageUpload_dropzoneItem-width: 120px;
  --AwsImageUpload_dropzoneItem-height: 120px;
  --AwsImageUpload_dropzoneItem-margin: var(--spacing);
  --AwsImageUpload_dropzoneItem-boxShadow: var(--boxShadow1);
  --AwsImageUpload_dropzoneItem-backgroundColor: var(--surfaceDark);
  --AwsImageUpload_form_separator-size: 8px;

  /*
   * Bubble
   */
  --Bubble-padding: var(--spacingDouble);
  --Bubble-boxShadow: var(--boxShadow3);
  --Bubble-borderRadius: var(--borderRadius);
  --Bubble__received-backgroundColor: var(--surfaceLight);
  --Bubble__sent-backgroundColor: var(--surfacePrimary);

  /*
   * Button
   */
  --Button-height: 36px;
  --Button__sm-height: 24px;
  --Button-paddingH: var(--spacingDouble);

  /*
   * ChatView
   */
  --ChatView_TopBar-paddingV: var(--spacing);
  --ChatView_TopBar-paddingH: var(--spacingHalf);
  --ChatView_TopBar_score-fontSize: 90%;
  --ChatView_TopBar_score_label-color: var(--grayLight);
  --ChatView_TopBar_score_value-color: var(--gray);
  --ChatView_TopBar_score_max-color: var(--gray);
  --ChatView_scrollView-maxWidth: 800px;
  --ChatView_scrollView-paddingT: var(--spacingDouble);
  --ChatView_scrollView-paddingB: 60px;
  --ChatView_scrollView-paddingH: var(--spacingDouble);
  --ChatView_item-paddingB: var(--spacingDouble);
  --ChatView_scrollDownButton-size: 40px;
  --ChatView_scrollDownButton-spacing: var(--spacingDouble);
  --ChatView_scrollDownButton-backgroundColor: var(--surfaceAccent);

  /*
   * ChatViewOverlay
   */
  --ChatViewOverlay-zIndex: 10;
  --ChatViewOverlay-backgroundColor: hsla(0, 0%, 0%, 0.5);

  /*
   * ChoicesSend
   */
  --ChoicesSend_text_marginR: var(--spacing);
  --ChoicesSend_text-fontSize: 90%;

  /*
   * EventMessage
   */
  --EventMessage-paddingV: var(--spacing);
  --EventMessage-paddingH: var(--spacingDouble);
  --EventMessage-borderRadius: var(--borderRadiusHalf);
  --EventMessage-boxShadow: var(--boxShadow2);
  --EventMessage-backgroundColor: var(--surfaceAccent);

  /*
   * HistoryComponent
   */
  --HistoryComponent_bottomBar-marginT: var(--spacing);
  --HistoryComponent_bottomBar_button-height: 24px;
  --HistoryComponent_bottomBar_button-marginL: var(--spacing);
  --HistoryComponent_bottomBar_button-borderRadius: var(--borderRadiusHalf);
  --HistoryComponent_bottomBar_popover-zIndex: 20;
  --HistoryComponent_bottomBar_popover-paddingV: var(--spacing3by2);
  --HistoryComponent_bottomBar_popover-paddingH: var(--spacing3by2);
  --HistoryComponent_bottomBar_popover-boxShadow: var(--boxShadow4);
  --HistoryComponent_bottomBar_popover-borderRadius: var(--borderRadius);
  --HistoryComponent_bottomBar_popover-backgroundColor: var(--surfaceLight);
  --HistoryComponent_scorePending-marginT: var(--spacingHalf);
  --HistoryComponent_scorePending-color: var(--gray);
  --HistoryComponent_scorePending-fontSize: 80%;

  /*
   * HtmlInput
   */
  --HtmlInput-minWidth-mdUp: 400px;
  --HtmlInput_form_separator-size: 8px;

  /*
   * HtmlTextarea
   */
  --HtmlTextarea-minWidth-mdUp: 400px;
  --HtmlTextarea_form_separator-size: 8px;

  /*
   * InputField
   */
  --InputField-height: 36px;
  --InputField-paddingH: var(--spacing);

  /*
   * KeyboardHint
   */
  --KeyboardHint-color: var(--grayLight);
  --KeyboardHint-fontSize: 75%;

  /*
   * LargeChoice
   */
  --LargeChoice-width: 250px;
  --LargeChoice-boxShadow: var(--boxShadow2);
  --LargeChoice-borderRadius: var(--borderRadiusHalf);
  --LargeChoice-highlightColor: var(--highlight);
  --LargeChoice_body-paddingV: var(--spacing);
  --LargeChoice_body-paddingH: var(--spacing);
  --LargeChoice_body-backgroundColor: var(--surfacePrimary);
  --LargeChoice_action-paddingV: var(--spacingHalf);
  --LargeChoice_action-backgroundColor: var(--primary);
  --LargeChoice_action-color: var(--onPrimary);
  --LargeChoice_action_index-size: 22px;
  --LargeChoice_action_index-marginL: var(--LargeChoice_action-paddingV);
  --LargeChoice_action_index-borderRadius: calc(
    var(--LargeChoice-borderRadius) - 1px
  );

  /*
   * LargeChoices
   */
  --LargeChoices_item-paddingR: var(--spacing);
  --LargeChoices_item-paddingB: var(--spacing);

  /*
   * LightboxMessage
   */
  --LightboxMessage_bubble-width: 400px;
  --LightboxMessage_image_more-backgroundColor: rgba(0, 0, 0, 0.5);
  --LightboxMessage_image_more-fontSize: 150%;
  --LightboxMessage_image_more-color: white;

  /*
   * LoomEmbed
   */
  --LoomEmbed-maxWidth: 496px;
  --LoomEmbed-placeholder-backgroundColor: black;
  --LoomEmbed-placeholder-color: white;

  /*
   * SmallChoice
   */
  --SmallChoice-paddingV: var(--spacing);
  --SmallChoice-paddingH: var(--spacingDouble);
  --SmallChoice-boxShadow: var(--boxShadow2);
  --SmallChoice-borderRadius: 20px;
  --SmallChoice-highlightColor: var(--highlight);
  --SmallChoice-backgroundColor: var(--primary);
  --SmallChoice-color: var(--onPrimary);

  /*
   * SmallChoices
   */
  --SmallChoices_item-paddingR: var(--spacing);
  --SmallChoices_item-paddingB: var(--spacing);

  /*
   * StackedChoice
   */
  --StackedChoice-maxWidth: 767px; /* sm */
  --StackedChoice-paddingV: var(--spacing);
  --StackedChoice-paddingH: var(--spacing);
  --StackedChoice-boxShadow: var(--boxShadow2);
  --StackedChoice-borderRadius: var(--borderRadiusHalf);
  --StackedChoice-highlightColor: var(--highlight);
  --StackedChoice-backgroundColor: var(--surfacePrimary);
  --StackedChoice-spacing: var(--spacing);
  --StackedChoice_action-width: 54px; /* Trial and error */
  --StackedChoice_action-backgroundColor: var(--primary);
  --StackedChoice_action-color: var(--onPrimary);
  --StackedChoice_action-paddingV: calc(var(--spacing) / 4);
  --StackedChoice_action-paddingH: var(--spacingHalf);
  --StackedChoice_body_img-maxHeight: 150px;
  --StackedChoice_index-size: 22px;
  --StackedChoice_index-borderRadius: calc(
    var(--StackedChoice-borderRadius) - 1px
  );
  --StackedChoice_index-color: var(--gray);

  /*
   * StackedChoices
   */
  --StackedChoices_item-paddingB: var(--spacing);

  /*
   * TextareaField
   */
  --TextareaField-paddingV: var(--spacing);
  --TextareaField-paddingH: var(--spacing);

  /*
   * VideoMessage
   */
  --VideoMessage-maxWidth: 496px;
}

/* mdUp */
@media screen and (min-width: 768px) {
  .ChatView_rootCommon___e93f0 {
  }

  .ChatView_root___e93f0 {
  }
}

/* lgUp */
@media screen and (min-width: 992px) {
  .ChatView_rootCommon___e93f0 {
  }

  .ChatView_root___e93f0 {
  }
}

/* xl */
@media screen and (min-width: 1200px) {
  .ChatView_rootCommon___e93f0 {
  }

  .ChatView_root___e93f0 {
  }
}

/* lgDown */
@media screen and (max-width: 1199px) {
  .ChatView_rootCommon___e93f0 {
  }

  .ChatView_root___e93f0 {
  }
}

/* mdDown */
@media screen and (max-width: 991px) {
  .ChatView_rootCommon___e93f0 {
  }

  .ChatView_root___e93f0 {
  }
}

/* sm */
@media screen and (max-width: 767px) {
  .ChatView_rootCommon___e93f0 {
    --ChatView_scrollView-paddingB: 40px;
    --ChatView_scrollDownButton-size: 32px;
    --ChatView_scrollDownButton-spacing: var(--spacing);
  }

  .ChatView_root___e93f0 {
  }
}

/* md */
@media screen and (min-width: 768px) and (max-width: 991px) {
  .ChatView_rootCommon___e93f0 {
  }

  .ChatView_root___e93f0 {
  }
}

/* lg */
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .ChatView_rootCommon___e93f0 {
  }

  .ChatView_root___e93f0 {
  }
}

/* End: All styling variables */

.ChatView_rootCommon___e93f0 * {
  box-sizing: border-box;
}

.ChatView_root___e93f0 {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.ChatView_runModeBadge___e93f0 {
  position: absolute;
  top: 4px;
  right: 4px;
  padding: 2px 4px;
  border-radius: 4px;
  background-color: #ff3434;
  color: #ffffff;
  font-size: 85%;
}

.ChatView_body___e93f0 {
  flex-grow: 1;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.ChatView_scrollOuter___e93f0 {
  /* Without this, scrolling doesn't seem to work when this component is placed
   * in a non-absolute height parent (i.e. a parent that does not have a number
   * height such as 300px or 20rem).
   */
  height: 100%;
}

.ChatView_overlaySite___e93f0 {
  position: relative;
  min-height: 100%;
}

.ChatView_scrollInner___e93f0 {
  position: relative;
  max-width: var(--ChatView_scrollView-maxWidth);
  margin: 0 auto;
  padding: var(--ChatView_scrollView-paddingT)
    var(--ChatView_scrollView-paddingH) var(--ChatView_scrollView-paddingB);
}

.ChatView_item___e93f0 {
  position: relative;
  padding-bottom: var(--ChatView_item-paddingB);
}

.ChatView_scrollDownButton___e93f0 {
  position: fixed;
  right: var(--ChatView_scrollDownButton-spacing);
  bottom: var(--ChatView_scrollDownButton-spacing);
}

.EventMessage_root___a29fd {
  display: flex;
  justify-content: center;
}

.EventMessage_inner___a29fd {
  padding: var(--EventMessage-paddingV) var(--EventMessage-paddingH);
  border-radius: var(--EventMessage-borderRadius);
  box-shadow: var(--EventMessage-boxShadow);
  background-color: var(--EventMessage-backgroundColor);
}

.Avatar_root___b7f33 {
  flex-shrink: 0;
  width: var(--Avatar-size);
  height: var(--Avatar-size);
  margin-right: var(--Avatar-marginR);
  box-shadow: var(--boxShadow3);
  border-radius: calc(var(--Avatar-size) / 2);
  background-color: var(--Avatar-backgroundColor);
}

.Avatar_img___b7f33 {
  object-fit: cover;
  width: var(--Avatar-size);
  height: var(--Avatar-size);
  border-radius: calc(var(--Avatar-size) / 2);
}

.Avatar_placeholderRoot___b7f33 {
  flex-shrink: 0;
  width: var(--Avatar-size);
  margin-right: var(--Avatar-marginR);
}

.Bubble_root___51a03 {
  display: flex;
  align-items: flex-start;
}

.Bubble_root__received___51a03 {
  justify-content: flex-start;
}

.Bubble_root__sent___51a03 {
  justify-content: flex-end;
}

.Bubble_content__animated___51a03 {
  --translateX: 50px;
  --animationDuration: 200ms;
}

.Bubble_content___51a03 {
  padding: var(--Bubble-padding);
  box-shadow: var(--Bubble-boxShadow);
}

.Bubble_content__received___51a03 {
  border-radius: 0 var(--Bubble-borderRadius) var(--Bubble-borderRadius)
    var(--Bubble-borderRadius);
  background-color: var(--Bubble__received-backgroundColor);
}

.Bubble_content__sent___51a03 {
  border-radius: var(--Bubble-borderRadius) 0 var(--Bubble-borderRadius)
    var(--Bubble-borderRadius);
  background-color: var(--Bubble__sent-backgroundColor);
}

@keyframes Bubble_slideRight___51a03 {
  0% {
    transform: translateX(calc(-1 * var(--translateX)));
  }
  100% {
    transform: translateX(0);
  }
}

.Bubble_content__animated___51a03.Bubble_content__received___51a03 {
  animation: Bubble_slideRight___51a03 var(--animationDuration);
}

@keyframes Bubble_slideLeft___51a03 {
  0% {
    transform: translateX(var(--translateX));
  }
  100% {
    transform: translateX(0);
  }
}

.Bubble_content__animated___51a03.Bubble_content__sent___51a03 {
  animation: Bubble_slideLeft___51a03 var(--animationDuration);
}

.LightboxMessage_root___41fe8 {
}

.LightboxMessage_bubble___41fe8 {
  width: 100%;
  max-width: var(--LightboxMessage_bubble-width);
}

.LightboxMessage_imageGrid___41fe8 {
  display: flex;
  flex-wrap: wrap;
}

.LightboxMessage_image___41fe8 {
  flex-basis: 50%;
  position: relative;
  width: 50%;
  height: 50%;
  overflow: hidden;
  padding-bottom: 50%;
  cursor: pointer;
}

.LightboxMessage_image___41fe8:hover {
  filter: brightness(95%);
}

.LightboxMessage_image__single___41fe8 {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  padding-bottom: 100%;
}

.LightboxMessage_image___41fe8 > img {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.LightboxMessage_image_more___41fe8 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--LightboxMessage_image_more-backgroundColor);
  font-size: var(--LightboxMessage_image_more-fontSize);
  color: var(--LightboxMessage_image_more-color);
}

.LightboxMessage_lightboxCaption___41fe8 > a {
  color: white;
}

.AudioPlayer_root___aa01d {
  --button-width: 20px;

  display: inline-block;
}

.AudioPlayer_controls___aa01d {
  display: flex;
  align-items: center;
}

.AudioPlayer_controls___aa01d > *:not(:last-child) {
  margin-right: 8px;
}

.AudioPlayer_button___aa01d {
  flex-shrink: 0;
  width: var(--button-width);
  color: var(--gray);
  cursor: pointer;
}

.AudioPlayer_button___aa01d:hover {
  filter: brightness(85%);
}

.AudioPlayer_volume___aa01d {
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  width: var(--button-width);
  transition: width 150ms;
}

.AudioPlayer_volume___aa01d > *:not(:last-child) {
  margin-right: 8px;
}

.AudioPlayer_volume___aa01d.AudioPlayer___isSliderVisible___aa01d {
  width: 80px;
}

.AudioPlayer_duration___aa01d {
  flex-shrink: 0;
  font-size: 90%;
  color: var(--gray);
}

.MdWithAudioMessage_root___3232a {
}

.MdWithAudioMessage_audio___3232a {
  max-width: 250px;
  margin-top: var(--spacingDouble);
}

.MultiAnswerMessage_root___93690 {
  display: flex;
  justify-content: flex-end;
}

.MultiAnswerMessage_largeChoices___93690 {
  display: flex;
  overflow-x: auto;
}

.MultiAnswerMessage_largeChoices_item___93690 {
  padding-right: var(--LargeChoices_item-paddingR);
  padding-bottom: var(--LargeChoices_item-paddingB);
}

.MultiAnswerMessage_largeChoices_item___93690:last-child {
  padding-right: 0;
}

.MultiAnswerMessage_stackedChoices___93690 {
  display: flex;
  flex-direction: column;
}

.MultiAnswerMessage_stackedChoices_item___93690 {
  padding-bottom: var(--StackedChoices_item-paddingB);
}

.MultiAnswerMessage_stackedChoices_item___93690:last-child {
  padding-bottom: 0;
}

.MultiAnswerMessage_smallChoices___93690 {
  display: flex;
  flex-wrap: wrap;
}

.MultiAnswerMessage_smallChoices_item___93690 {
  padding-right: var(--SmallChoices_item-paddingR);
  padding-bottom: var(--SmallChoices_item-paddingB);
}

.MultiAnswerMessage_smallChoices_item___93690:last-child {
  padding-right: 0;
}

.LoomEmbed_root___f647c {
  max-width: var(--LoomEmbed-maxWidth);
  margin-left: auto;
  margin-right: auto;
}

.LoomEmbed_placeholder___f647c {
  position: relative;
  padding-top: 75%; /* Player ratio: 100 / (496 / 372) */
}

.LoomEmbed_placeholder_contents___f647c {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: var(--spacing);
  background-color: var(--LoomEmbed-placeholder-backgroundColor);
  color: var(--LoomEmbed-placeholder-color);
}

.VideoMessage_root___4bb26 {
  max-width: var(--VideoMessage-maxWidth);
  margin-left: auto;
  margin-right: auto;
}

.VideoMessage_playerWrapper___4bb26 {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.VideoMessage_player___4bb26 {
  position: absolute;
  top: 0;
  left: 0;
}

.ChatViewOverlay_root___01650 {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: var(--ChatViewOverlay-zIndex);
  background-color: var(--ChatViewOverlay-backgroundColor);
}

.HistoryComponent_root___7e635 {
  --answersButton-width: var(--HistoryComponent_bottomBar_button-height);
  --comments-offsetRight: calc(var(--answersButton-width) + var(--spacing));
  --bottomBar_button-minWidth: var(--HistoryComponent_bottomBar_button-height);
}

.HistoryComponent_scorePending___7e635 {
  margin-top: var(--HistoryComponent_scorePending-marginT);
  text-align: right;
  color: var(--HistoryComponent_scorePending-color);
  font-size: var(--HistoryComponent_scorePending-fontSize);
}

.HistoryComponent_bottomBar___7e635 {
  margin-top: var(--HistoryComponent_bottomBar-marginT);
}

.HistoryComponent_bottomBar_buttons___7e635 {
  display: flex;
  justify-content: flex-end;
}

.HistoryComponent_bottomBar_popovers___7e635 {
  position: relative;
}

.HistoryComponent_bottomBar_button___7e635 {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: var(--bottomBar_button-minWidth);
  height: var(--HistoryComponent_bottomBar_button-height);
  margin-left: var(--HistoryComponent_bottomBar_button-marginL);
  border-radius: var(--HistoryComponent_bottomBar_button-borderRadius);
  background-color: var(--HistoryComponent_bottomBar_popover-backgroundColor);
  cursor: pointer;
}

.HistoryComponent_bottomBar_button___7e635:hover {
  filter: brightness(85%);
}

.HistoryComponent_bottomBar_button__popoverOpen___7e635 {
  z-index: calc(var(--HistoryComponent_bottomBar_popover-zIndex) + 1);
}

.HistoryComponent_bottomBar_popover___7e635 {
  position: absolute;
  top: var(--spacing);
  right: 0;
  max-width: 100%;
  z-index: var(--HistoryComponent_bottomBar_popover-zIndex);
  padding: var(--HistoryComponent_bottomBar_popover-paddingV)
    var(--HistoryComponent_bottomBar_popover-paddingH);
  box-shadow: var(--HistoryComponent_bottomBar_popover-boxShadow);
  border-radius: var(--HistoryComponent_bottomBar_popover-borderRadius);
  background-color: var(--HistoryComponent_bottomBar_popover-backgroundColor);
  animation: HistoryComponent_fadeIn___7e635 500ms;
}

@keyframes HistoryComponent_fadeIn___7e635 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.HistoryView_externalMessage___7632a {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: var(--spacing) 0;
}

.ScrollDownButton_root___cdba0 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--ChatView_scrollDownButton-size);
  height: var(--ChatView_scrollDownButton-size);
  border: 1px solid var(--gray);
  border-radius: calc(var(--ChatView_scrollDownButton-size) / 2);
  box-shadow: var(--boxShadow1);
  background-color: var(--ChatView_scrollDownButton-backgroundColor);
  opacity: 0.6;
  cursor: pointer;
}

.ScrollDownButton_root___cdba0:hover {
  opacity: 1;
}

.TopBar_root___8d707 {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  padding: var(--ChatView_TopBar-paddingV) var(--ChatView_TopBar-paddingH);
}

.TopBar_score___8d707 {
  display: flex;
  font-size: var(--ChatView_TopBar_score-fontSize);
}

.TopBar_score_label___8d707 {
  margin-right: var(--spacing);
  text-transform: uppercase;
  color: var(--ChatView_TopBar_score_label-color);
}

.TopBar_score_value___8d707 {
  min-width: 50px;
  flex-grow: 1;
  text-align: center;
  color: var(--ChatView_TopBar_score_value-color);
  font-weight: bold;
}

.TopBar_score_max___8d707 {
  flex-shrink: 0;
  text-align: center;
  color: var(--ChatView_TopBar_score_max-color);
  font-weight: bold;
}

/* We separate this out from `root`. If you ever want to render some chatbot
 * components outside of the ChatSequenceView DOM tree (such as in a modal),
 * you can wrap them in a div with this className. This will be required only
 * for those components that aren't already within a ChatView (for the latter
 * you need to use a similar construct in ChatView.module.css).
 * Also remember to add the `.root` class from `BaseCssVariables.module.css`,
 * since it has all the basic CSS variables that all these styles depend on.
 */
.ChatSequenceView_rootCommon___c8c28 {
  /*
   * ChatSeqViewMenu
   */
  --ChatSeqViewMenu-padding: var(--spacingHalf);
  --ChatSeqViewMenu_OptionBadge-minWidth: 40px;
  --ChatSeqViewMenu_OptionBadge-marginH: var(--spacingHalf);
  --ChatSeqViewMenu_OptionBadge-padding: 2px;
  --ChatSeqViewMenu_OptionBadge-borderRadius: var(--borderRadius);
  --ChatSeqViewMenu_OptionBadge-backgroundColor: var(--surface);
  --ChatSeqViewMenu_OptionBadge-color: black;
}

.ChatSequenceView_root___c8c28 {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.ChatSequenceView_chatView___c8c28 {
  flex: 1;
  height: 100%;
  overflow-y: hidden;
}

.ChatSeqViewMenu_root___3d136 {
  flex-shrink: 0;
  box-shadow: var(--boxShadow3);
  padding: var(--ChatSeqViewMenu-padding);
}

.ChatSeqViewMenu_OptionBadge___3d136 {
  display: flex;
  align-items: center;
  min-width: var(--ChatSeqViewMenu_OptionBadge-minWidth);
  margin: 0 var(--ChatSeqViewMenu_OptionBadge-marginH);
  padding: var(--ChatSeqViewMenu_OptionBadge-padding);
  border-radius: var(--ChatSeqViewMenu_OptionBadge-borderRadius);
  background-color: var(--ChatSeqViewMenu_OptionBadge-backgroundColor);
  color: var(--ChatSeqViewMenu_OptionBadge-color);
}

.ChatSeqViewMenu_OptionBadge_sno___3d136 {
  flex: 1;
  margin-right: 4px;
  text-align: right;
}

.ChatSeqViewMenu_option___3d136 {
  display: flex;
  align-items: center;
}

.ChatSeqViewMenu_option_title___3d136 {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ChatSeqViewMenu_option_afterBadge___3d136 {
  flex-shrink: 0;
}

.ChatSeqViewMenu_option_afterTitle___3d136 {
  flex-shrink: 0;
}

.ChatSeqViewMenu_singleValue___3d136 {
  width: calc(100% - 16px); /* 16px by trial and error */
}

.DefaultNextComponent_message___a3f77 {
  margin-bottom: var(--spacingDouble);
}

/* Don't use any CSS variables here.
 * Since this component it used to preview the theme, we don't want it to be
 * themeable itself. */

.ThemePreview_items___20246 {
  max-width: 400px;
  overflow-x: hidden;
  margin: 0 auto;
  padding: 8px;
}

.ThemePreview_Item___20246:not(:last-child) {
  margin-bottom: 12px;
}

.ThemePreview_Item_title___20246 {
  margin-bottom: 8px;
  font-size: 90%;
  color: gray;
}
