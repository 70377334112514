.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: var(--spacingHalf);

  --editorPadding: var(--spacingHalf);
}

.label {
  display: flex;
  align-items: center;
  padding: 1px var(--spacingHalf);
  background-color: var(--surfaceI18n);
  border-radius: 2px 2px 0 0;
  color: var(--i18n);
  font-size: 65%;
}

.editor {
  width: 100%;
  padding: var(--editorPadding) 0;
  background-color: var(--surfaceI18n);
  border-radius: 0 0 0 var(--editorPadding);
}
