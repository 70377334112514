.root {
  margin-bottom: calc(var(--spacing) * 2);
}
.root:last-child {
  margin-bottom: 0;
}

.title {
  margin-bottom: calc(var(--spacing) * 2);
  padding-bottom: var(--spacing);
  border-bottom: 1px solid var(--surfaceDark);
  text-align: center;
  color: var(--gray);
  font-size: 85%;
  font-weight: bold;
  text-transform: uppercase;
}
