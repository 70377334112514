.pointagram {
  padding: var(--spacing);
  border-radius: var(--borderRadius);
  background-color: var(--surfaceAccent);
}

.pointagram_title {
  display: flex;
  align-items: center;
}

.thinkificAuthExpired {
  display: flex;
  align-items: center;
  border-radius: var(--borderRadius);
  border: 1px solid var(--surfaceDanger);
  padding: var(--spacingDouble);
}

.thinkificAuthExpired .errorIcon {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  margin-right: var(--spacingDouble);
}
