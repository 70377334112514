.root {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow: auto;
}

.container {
  position: relative;
  display: flex;
  margin: 0 auto;
  border: none;
  text-overflow: clip;
}
