.root {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.topBar {
  flex-shrink: 0;
  display: flex;
  min-height: 36px; /*Trial and error */
  padding: 0 var(--spacing);
  background-color: var(--surfaceLight);
}

.topBarMain {
  flex: 1;
  display: flex;
  align-items: center;
}

.topBarRight {
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.actionMenu {
  z-index: 30;
}

.wordCount {
  text-align: center;
  font-size: 28px;
}

.wordCountMessage {
  margin-top: var(--spacing);
  font-size: 90%;
  color: var(--grayDark);
}

.prompts {
  flex: 1;
  overflow-y: auto;
}
