.root {
  /* !important needed to override formStyles */
  display: flex !important;
  margin-top: var(--spacingHalf); /* Trial and error */
}

.input {
  flex-shrink: 0;
  margin-top: var(--spacingHalf); /* Trial and error */
}

.label {
  flex: 1;
  padding-left: var(--spacingHalf);
}

.label_title {
  margin-bottom: var(--spacingHalf);
}

.label_subtitle {
  margin-bottom: var(--spacingHalf);
  color: var(--gray);
}
