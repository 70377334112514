.gravatar {
  width: 24px;
  height: 24px;
  background-color: var(--surfaceLight); /* Shows while gravatar is loading */
}

.profile {
  text-align: left;
}

.profile_name {
  color: var(--primary);
  font-size: 90%;
}

.profile_email {
  font-size: 85%;
}

.sectionTitle {
  margin-bottom: var(--spacing3by2);
  font-size: 80%;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--grayDark);
}
