html,
body {
  width: 100%;
  height: 100%;
  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Same as chatbot-embed for uniformity.
   * Defined here so that it remains in scope for `ChatView`s within modals too,
   * since modals are rendered directly in `body`, outside the normal hierarchy.
   */
  --chatBackground: #F0F0F0;
}

body * {
  box-sizing: border-box;
}

#root {
  width: 100%;
  height: 100%;
}
