.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.logo {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  color: var(--accent);
}

.logo_icon {
  width: 60px;
  height: 60px;
  margin-right: var(--spacing);
}

.logo_name {
  font-size: 36px;
}

.authForm {
  width: min(calc(100% - var(--spacing) * 2), 360px);
}
