/* Rendered within ChatView, so beware of clashing CSS vars */

.root {
}

.timeSpent {
  display: inline-flex;
  align-items: center;
  margin-top: var(--spacing);
  margin-left: 36px; /* Trial and error */
  font-size: 80%;
  color: var(--grayDark);
}

.timeSpent.__isOutlier .timeSpent_actual {
  color: var(--danger);
  text-decoration: line-through;
}

.row {
  display: flex;
  position: relative;
  color: var(--gray);
}

.inputsIcon {
  position: absolute;
  top: -4px;
  left: -12px;
}
