.root {
  position: absolute;
  z-index: 1;
  opacity: 0;
  animation: fadeIn 100ms ease-in 100ms forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.6;
  }
}

.button {
  display: flex;
  margin-top: -2px; /* Value depends on h/w and message item padding */
  margin-left: 60px;
  box-shadow: var(--boxShadow4);
  font-size: 75%;
}

.button__isAbove {
  margin-top: -18px; /* Value depends on h/w and answer editor padding */
}

.buttonPart {
  padding: 2px var(--spacingHalf);
  background-color: var(--grayDark);
  color: white;
}

.selectedOption {
  display: flex;
  align-items: center;
  width: 100%;
}

.optionLabel {
  flex: 1;
  text-align: center;
  margin-left: var(--spacingHalf);
}
