.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

/* Match styling with filter select-box */
.header {
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 46px;
  padding: 0 var(--spacing);
  background-color: var(--surfaceLight);
}

.timeSpent {
  display: inline-flex;
  align-items: center;
  font-size: 90%;
}

.destroyButton {
  background-color: var(--surfaceDanger);
}

.responseView {
  position: relative;
  flex: 1;
  overflow: hidden;
}

.responseViewNav {
  position: absolute;
  top: 30px; /* To avoid "Readonly" badge */
  right: 20px; /* To avoid scrollbar on some browsers */
  display: flex;
  z-index: 1;
}
