/* Common styles that can be used to wrap form components */

.field {
  margin-bottom: var(--spacing);
}

.field:last-of-type {
  margin-bottom: 0;
}

.field > label {
  display: inline-block;
  margin-bottom: var(--spacingHalf);
}

.field > .input {
  width: 100%;
}

/* Useful for things like a text box followed by a suffix */
.field > .inputRow {
  display: flex;
  align-items: center;
  width: 100%;
}

.inputRow_item:not(:last-child) {
  margin-right: var(--spacingHalf);
}

.actions {
  display: flex;
  margin-top: var(--spacing3by2);
}

.actions > *:not(:last-child) {
  margin-right: var(--spacing);
}
