:root {
  --spacing: 8px;
  --spacingHalf: calc(var(--spacing) / 2);
  --spacing3by4: calc(var(--spacing) * 0.75);
  --spacing3by2: calc(var(--spacing) * 1.5);
  --spacingDouble: calc(var(--spacing) * 2);
  --borderRadius: 8px;
  --borderRadiusHalf: calc(var(--borderRadius) / 2);

  --elevation1: 3px;
  --elevation2: 5px;
  --elevation3: 8px;
  --elevation4: 13px;
  --elevationColor: hsla(0, 0%, 0%, 0.1);

  --boxShadow1: 0 0 var(--elevation1) 0 var(--elevationColor);
  --boxShadow2: 0 0 var(--elevation2) 0 var(--elevationColor);
  --boxShadow3: 0 0 var(--elevation3) 0 var(--elevationColor);
  --boxShadow4: 0 0 var(--elevation4) 0 var(--elevationColor);

  --primary: hsl(215, 84%, 10%);
  --accent: hsl(180, 58%, 45%);
  --tertiary: hsl(27, 100%, 60%); /* For very occasional use. Must stand out */
  --tertiaryDark: hsl(27, 100%, 50%);
  --danger: hsl(350, 70%, 40%);
  --success: hsl(116, 90%, 35%);
  --i18n: hsl(300, 80%, 40%);
  --grayDark: hsl(0, 0%, 40%);
  --gray: hsl(0, 0%, 60%);
  --grayLight: hsl(0, 0%, 78%);

  --surface: hsl(0, 0%, 90%);
  --surfaceLight: hsl(0, 0%, 96%);
  --surfaceDark: hsl(0, 0%, 84%);
  --surfacePrimary: hsl(215, 84%, 90%);
  --surfacePrimaryLight: hsl(215, 84%, 94%);
  --surfacePrimaryDark: hsl(215, 84%, 86%);
  --surfaceAccent: hsl(180, 58%, 94%);
  --surfaceAccentLight: hsl(180, 58%, 96%);
  --surfaceAccentDark: hsl(180, 58%, 90%);
  --surfaceDanger: hsl(350, 70%, 88%);
  --overlayDanger: hsla(350, 70%, 88%, 0.4);
  --surfaceSuccess: hsl(116, 90%, 80%);
  --surfaceI18n: hsl(300, 80%, 96%);

  /* In between grayLight and surfaceDark */
  --button: hsl(0, 0%, 80%);
}
