.root {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.addOrAttachFlowLayout {
  max-height: 160px;
}

.addMoreFlowsContainer {
  /* Left margin by trial and error */
  margin: var(--spacing3by2) 0 var(--spacingDouble) 26px;
}

.detachedDivider {
  width: 100%;
  text-align: center;
  margin: var(--spacing) 0;
  margin-top: calc(var(--spacing) * 4);
  color: var(--gray);
  text-transform: uppercase;
}

.topBar {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing);
  padding: var(--spacing);
}
.topBar:empty {
  display: none;
}

.updatingWrapper {
  position: relative;
}

.updating {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}
