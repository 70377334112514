.root {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 50;
  margin: var(--spacing);
  padding: var(--spacing3by2);
  box-shadow: var(--boxShadow2);
  border-radius: var(--borderRadius);
  background-color: hsla(0, 0%, 0%, 0.6);
  color: white;
  opacity: 0;
  animation: fadeIn 200ms ease-in 300ms forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
