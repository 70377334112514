.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.root > *:not(:last-child) {
  margin-bottom: var(--spacingDouble);
}

.spinner {
  width: 30px;
  height: 30px;
  color: var(--gray);
  opacity: 0;
  animation:
      fadeIn 200ms ease-in 300ms forwards,
      spin 1.5s linear infinite;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}

.headerIcon {
  height: 50px;
}
