.root {
  position: relative;
  display: flex;
  flex-direction: column;
}

.quickResponseEditor {
  display: flex;
}

.dash {
  color: var(--gray);
  margin-right: var(--spacingHalf);
}

.leftColumn {
}

.rightColumn {
  flex: 1;
  margin-left: var(--spacing);
}

.message {
  display: flex;
  /* Top margin set by trial-and-error */
  margin: 0 0 var(--spacingHalf);
}

.message_placeholder {
  color: var(--gray);
}

.message_left {
  flex: 1;
}

.message_right {
  flex-shrink: 0;
}

.actionMenu {
  z-index: 30;
}

.actionMenuButton {
  color: var(--grayLight);
}

.tipContent {
  margin: 0;
  padding: 0;
  list-style: none;
}

.tipContent_label {
  font-family: monospace;
}

.repeatAnswers {
  width: 36px;
  padding: var(--spacingHalf);
  border-radius: var(--borderRadiusHalf);
  background-color: var(--surfaceAccent);
  color: var(--accent);
  text-align: center;
  font-size: 70%;
  text-transform: uppercase;
}

.repeatAnswers__on {
  color: var(--primary);
}

.bottomBar {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
}

.useListButton {
  display: inline-flex;
  align-items: center;
  padding: var(--spacingHalf) 0;
  color: var(--gray);
}

.useListButton_label {
  margin-left: var(--spacingHalf);
  font-size: 80%;
}
