.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.title {
  display: flex;
  align-items: center;
  font-size: 18px;
}

.title_sequence {
  color: var(--gray);
  white-space: nowrap;
}

.title_text {
  /* To match `NameEditor` left-padding */
  margin-left: calc(var(--spacing) + 1px);
  color: var(--grayDark);
}

.title_versionSelect {
  margin-left: var(--spacing);
}

.promotedToast {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.editor {
  flex: 1;
  /* For some reason, using overflow-y instead of overflow causes a
   * react-beautiful-dnd warning as follows:
   * "Droppable: unsupported nested scroll container detected."
   * Seems like they have faulty logic for generating this warning? */
  overflow: hidden;
}
