.root {
  --nextKey_paddingV: var(--spacingHalf);

  margin-bottom: var(--spacingHalf);
}

.addAnswer {
  display: flex;
  align-items: center;
  padding: var(--spacingHalf) 0;
  color: var(--gray);
}

.addAnswer_label {
  margin-left: var(--spacingHalf);
  font-size: 80%;
}

.answerRef {
}
