.root {
  display: flex;
  align-items: stretch;
}

.items {
  flex-shrink: 0;
  width: 50%;
  min-width: 300px;
  overflow-y: auto;
}

.item {
  display: flex;
  padding: var(--spacing3by2) var(--spacingDouble);
  border-bottom: 1px solid var(--surfaceDark);
  background-color: white;
}

.item:hover {
  filter: brightness(0.97);
}

.item_body {
  flex: 1;
}

.item_name {
  margin-bottom: var(--spacing3by4);
}

.item_description {
  color: var(--grayDark);
  font-size: 95%;
}

.item_useButton {
  visibility: hidden;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  margin-left: var(--spacingHalf);
}

.item__selected {
  background-color: var(--chatBackground);
}

.item__selected .item_name {
  color: var(--tertiaryDark);
}

.item__selected .item_useButton {
  visibility: visible;
}

.preview {
  flex: 1;
  width: 50%;
  min-width: 300px;
}
