.root {
  color: var(--gray);
  padding: 2px; /* Trial and error; try to keep really small */
}

.root:hover {
  filter: brightness(75%);
}

.root:active,
.isActive {
  filter: brightness(65%);
}
