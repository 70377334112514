.root {
  margin-left: 8px;
}

.input {
  width: 75px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-right: 2px;
  font-size: 12px;
}

.input > input::placeholder {
  color: var(--grayLight);
}
