.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.header {
  flex-shrink: 0;
  padding: var(--spacing);
}

.body {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.courseTree {
  flex: 1;
  overflow: auto;
}

.chapter {
  margin: var(--spacing);
  padding: var(--spacing);
  border-radius: var(--borderRadiusHalf);
  background-color: var(--surfaceAccent);
}

.chapter_id {
  margin-bottom: var(--spacingHalf);
  font-size: 80%;
  color: var(--accent);
}

.chapter_name {
  margin-bottom: var(--spacing);
  font-size: 110%;
  color: var(--grayDark);
}

.content {
  margin-bottom: var(--spacing);
  padding: var(--spacing);
  border-radius: var(--borderRadiusHalf);
  background-color: var(--surface);
  opacity: 0.4;
}

.content:last-child {
  margin-bottom: 0;
}

.content__relevant {
  box-shadow: var(--boxShadow4);
  background-color: var(--surfaceLight);
  opacity: 1;
}

.content_id {
  font-size: 80%;
  color: var(--gray);
}

.content__relevant .content_id {
  color: var(--tertiary);
}

.content_name {
  margin-bottom: var(--spacing);
}

.flowItem {
  margin-top: var(--spacingHalf);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.action:not(:last-child) {
  margin-right: var(--spacingHalf);
}
