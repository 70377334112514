.root {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
}

.floatingMenu {
  position: absolute;
  top: var(--spacing);
  right: var(--spacing);
  display: flex;
}

.floatingMenu_item:not(:last-child) {
  margin-right: var(--spacingHalf);
}

.innerRoot {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: var(--chatBackground);
}

.loading {
  background-color: var(--chatBackground);
}

.placeholder {
  display: flex;
  align-items: center;
  padding: var(--spacing);
  border: 1px dashed var(--grayLight);
  background-color: var(--surface);
  color: var(--grayDark);
}

.placeholder_icon {
  flex-shrink: 0;
  color: blue;
}

.placeholder_body {
  flex: 1;
  margin-left: var(--spacing);
}
