.RevertButton__revert {
  color: var(--tertiaryDark);
}

.WithRevertButton {
  display: flex;
  margin-bottom: var(--spacing);
}

.WithRevertButton_button {
  flex-shrink: 0;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-right: var(--spacingHalf);
  padding-right: var(--spacingHalf);
  border-right: 1px solid var(--grayLight);
}

.WithRevertButton_content {
  flex: 1;
}

.themeSettings {
  display: flex;
  max-width: 100%;
  overflow-x: auto;
}

.themeSettings_settings {
  flex: 1;
  min-width: 250px;
}

.themeSettings_preview {
  flex-shrink: 0;
  min-width: 250px;
  margin: var(--spacing);
  border-radius: var(--borderRadius);
  box-shadow: var(--boxShadow1);
  background-color: var(--chatBackground);
}

.chatSliderInput {
  width: 150px; /* Trial and error */
}

.moreSettingsBelow {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: var(--spacingDouble);
  font-size: 85%;
  color: var(--gray);
}

.chatDefaultDelayInput {
  width: 85px; /* Trial and error */
}
