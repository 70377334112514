.root {
  position: absolute;
  z-index: 1;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  margin-top: -7px; /* Value depends on h/w and answer editor padding */
  margin-left: 86px;
  box-shadow: var(--boxShadow4);
  border-radius: 50%;
  background-color: var(--surfaceAccentDark);
  color: var(--gray);
}

.button__isAbove {
  margin-top: -15px; /* Value depends on h/w and answer editor padding */
}
