.root {
}

.picker {
  margin: 0 var(--spacingHalf);
}

.picker :global(.react-date-picker__wrapper) {
  border-color: var(--gray);
  border-radius: var(--borderRadiusHalf);
}
