.forgotPasswordButton {
  padding: var(--spacingHalf) 0;
}

.switchMessage {
  margin-top: var(--spacing);
  text-align: center;
  color: var(--grayDark);
}

.inlineButton {
  display: inline-block;
}
